$header-primary-height-mobile: 90px !default;
$header-primary-height-tablet: 110px !default;
$header-primary-height-desktop: 150px !default;
$header-primary-background-color: $color__white !default;
$header-secondary-background-color: $color__secondary !default;
$header-secondary-height-tablet: 40px !default;
$header-secondary-height-desktop: 43px !default;

.c-header {
    &__wrapper {
        @apply fixed w-full z-30;

        top: 0;
        left: 0;

        @include mq("mobile-menu") {
            transform: translateY(0);
            transition: transform $timing--default $ease-std;
        }

        &.headroom--not-top {
            .a-logo__image {
                @include mq("lg") {
                    height: 90px;
                }
            }
        }

        &.headroom--pinned {
        }

        &.headroom--unpinned {
            position: fixed;
            left: 0;

            @include mq("mobile-menu") {
                transform: translateY(-$header-secondary-height-tablet);
            }

            @include mq("xxl") {
                transform: translateY(-$header-secondary-height-desktop);
            }
        }
    }

    &--primary {
        @apply relative;

        background-color: $header-primary-background-color;


        &:after {
            content: '';
            @apply absolute;

            width: calc(100% - 60px);
            left: 30px;
            margin-top: -40px;
            height: 40px;
            z-index: -1;
            box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.015), 0px 0px 20px rgba(0, 0, 0, 0.035), 0px 0px 90px rgba(0, 0, 0, 0.075);
        }
    }

    &--secondary {
        @apply absolute;

        @include mq("mobile-menu") {
            @apply relative block;

            // if you update the height, you'll need to update the Headroom offset in main.js
            height: $header-secondary-height-tablet;
            background-color: $header-secondary-background-color;

            @include mq("xxl") {
                height: $header-secondary-height-desktop;
            }
        }

        &.visible--mobile {
            @apply block;
        }
    }
}

.a-nav-toggle {
    @apply block self-center;

    @include mq("lg") {
        margin-right: 8%;
    }

    @include mq("mobile-menu") {
        @apply hidden;

    }
}
