.c-breadcrumbs {
    // background-color: #fff;
    > ul {
        margin-left: 0 !important;
    }

    &__item {
        + .c-breadcrumbs__item {
            &::before {}
        }
    }

    &__link {
        &:focus,
        &:hover {}

        &.utl-is-active {}
    }
}
