table {
    @apply mb-8 text-left;
}

td,
th {
    padding: 15px;
    border-bottom: 10px solid $color__white;
    text-align: left;

    > p {
        line-height: 1.3;
        margin-bottom: 0;
    }
}

th {
    background: $color__primary--alt;
    color: $color__white;

    & + th {
        border-left: 10px solid $color__white;
    }
}

td {
    background: $color__grey--100;
    color: $color__black;
    background: $color__grey--100;
    
    @include mq("lg") {
        min-width: 150px;
    }

    & + td {
        border-left: 10px solid $color__white;
    }
}
