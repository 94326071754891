.a-button {
    text-decoration: none;
    transition: all $timing--default $ease-std;

    &__arrow {
    }

    &--primary {
        text-transform: uppercase;
        text-align: right;
        @apply pt-4 pr-4 pb-2 pl-24;
        border-bottom: 8px solid $color__primary;
        font-size: .75rem;
        letter-spacing: 5px;

        @include attention() {
        }
    }

    &--secondary {
        @include attention() {
        }
    }

    &--default {
        @include attention() {
        }
    }

    &--outline {
        @include attention() {
        }
    }

    &--arrow {
        @include attention() {
        }

        &::after {
        }
    }

    &--more {
        margin: 0;
    }
}
