.nav--sitemap {
    @apply block my-12;

    &__list {
        list-style-type: disc;

        &__item {

        }

        &--indented {
            padding-left: 2em;
            list-style-type: circle;
        }
    }

    &__link {

    }
}
