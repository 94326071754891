.c-testimonial {
    align-items: center;
    padding: 20px 20px 40px 10px;
    background: $color__grey--100;
    margin: 1em 0;

    @include mq("sm") {
      @apply flex items-center;

      padding: 20px 40px 40px;
    }


    &__wrapper {
        @apply pb-12;

        @include mq("lg") {
          @apply my-24;

          max-width: 1000px;
          margin: 0 auto;
        }
    }

    &__image {
        margin: 0 auto;
        height: 80px;
        width: 80px;

        @include mq("sm") {
            @apply mt-6;

            height: 120px;
            width: 120px;
            min-width: 120px;
        }

        @include mq("lg") {
            width: 20%;
            height: auto;
        }
    }

    &__quote {
        margin: 1em;

        svg {
            height: 20px;
            width: 20px;
            color: $color__black;
        }
    }

    &__content {
        &__heading {

        }
    }
}

#show-more {
    display: none;
}
