.b-text {
    &.utl-align--center {
        @include mq("mobile-menu") {
            @apply mx-auto;
            max-width: 60%;
        }
    }
}

.two-columns {
    @apply my-8;

    column-fill: balance;

    > * {
        margin-top: 0 !important;
        margin-bottom: 2rem !important;
    }

    > hr {
        opacity: 0;
        height: 0;
        margin: 0;
        padding: 0;
        break-after: always;
    }
}
