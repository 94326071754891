$card-tease-background-colour: $color__grey--100 !default;
$card-tease-heading-weight: $font-weight-bold !default;

@if index($tease-types, card) {
    .a-tease--card {
        &__link {}

        &__image {}

        &__heading {
            @apply mt-3 mb-0;
            font-weight: $card-tease-heading-weight;
        }

        &__text {
            @apply py-4 px-8 w-full;
            justify-content: space-between;
            background-color: $card-tease-background-colour;
        }
    }
}
