.m-locations {
    @apply my-12;

    @include mq("lg") {
      @apply flex;
    }

    &__section {
        @apply pt-8 w-full;

        @include mq("md") {
            @apply pt-24;

        }
    }

    &__tabs {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        @include mq("lg") {
          display: block;
        }

        &__button {
            display: flex;
            align-items: center;
            padding: 5px 20px;
            background: $color__white;
            color: $color__black;
            border: 1px $color__grey--200 solid;
            width: 100%;
            margin-bottom: 5px;
            transition: $transition--default;

            @include mq("md") {
              width: 48%;
              margin-bottom: 20px;
            }

            @include mq("lg") {
              width: 300px;
              margin-right: 20px;
              padding: 20px 30px;
            }

            svg {
                color: $color__black;
                height: 25px;
                width: 25px;
                margin-right: 10px;
            }

            &__title {
                @apply mb-0;

                font-size: $font-size-large;
                font-weight: $font-weight-light;
                color: $color__grey--700;
                padding: 0.5em 0;
                transition: $transition--default;
            }

            &.active {
                background: $color__grey--100;
                border: 1px $color__grey--100 solid;
            }
            &:not(.active){
                @include attention() {
                  background: $color__primary;
                  border-color: $color__primary;

                  .c-locations__tabs__button__title {
                      padding-left: 5px;
                      color: $color__white;
                  }
                }
            }
        }
    }

    .js-locations-animate {
        @apply flex flex-col w-full;

        opacity: 0;
        margin-top: 100px;
        animation: locationAnimate 0.8s cubic-bezier(0.25, 0.1, 0.25, 1);
        animation-fill-mode: both;
    }

    @keyframes locationAnimate {
        0% {
            opacity: 0;
            margin-top: 100px;
        }
        100% {
            opacity: 1;
            margin-top: 0;
        }
    }

    &__location {
        background: $color__grey--100;
        width: 100%;

        &__wrapper {
            @apply w-full;
        }

        &__info {
            @apply px-8 py-8;

            &__address {
                @include mq($until: md) {
                    margin-bottom: 10px;

                }
            }
            &__details {
                list-style-type: none !important;
            }
        }

        &__map {
            min-height: 400px;
            width: 100%;
            background-color: $color__primary;
        }
    }
}

.link--blue {
    color: $color__primary;

    @include attention() {
      color: $color__primary--alt;
    }
}
