.practice{
    &__wrapper {
        @include mq($until: md) {
            margin-top: -30px;
            margin-left: -30px;
            margin-right: -30px;
        }

    @apply w-full relative flex flex-col;
    text-decoration: none;
    background-color: $color__secondary--5;

    @media (min-width: "1024px"){
        @apply w-full flex flex-row;
        width: 1700px;
        margin-left: -400px;
        justify-content: space-around;
    }
      @media (min-width: "1300px"){
          width: 1900px;
          margin-left: -400px;
      }
      @media (min-width: "1600px"){
          width: 2200px;
          margin-left: -430px;
      }
}
    &__list{
        @apply w-full relative;
        padding: 2rem;

        @media (min-width: "1024px"){
            width: 30%;
            margin-top: 4rem;
            margin-bottom: 4rem;
        }
        h3, .h3{
          padding-left: 2rem;
        }
    }
    &__text {
        @apply w-full relative;
        padding: 2rem;
        @media (min-width: "1024px"){
            @apply my-12;
            width: 25%;
            margin-left: -700px;
            margin-top: 6rem;
            margin-bottom: 4rem;
        }
    }
    &__button {
        // @apply w-full;
        padding: 0.5rem;
        color: #fff;
        text-align: right;
        border-bottom: 8px solid $color__primary;
        width: 40%;
        @include mq(md) {
            @apply w-1/2;
        }
        &--link{
          color: $color__grey--100;
          letter-spacing: 6px;
        }
    }
}
