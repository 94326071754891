$breadcrumbs-link-color: $color__secondary !default;
$breadcrumbs-link-color--hover: $color__primary--alt !default;
$breadcrumbs-link-color--active: $color__primary !default;
$breadcrumbs-separator-color: $color__grey--dark !default;

.c-breadcrumbs {
    @apply inline-block mt-6;

    @include mq("mobile-menu") {
        @apply mt-16;
    }

    &__item {
        display: inline;

        + .c-breadcrumbs__item {
            &:before {
                content: "/";
                padding: 0 7px 0 3px;
                color: $breadcrumbs-separator-color;
            }
        }
    }
    &__link {
        color: $breadcrumbs-link-color;

        &:focus,
        &:hover {
            color: $breadcrumbs-link-color--hover;
        }

        &.utl-is-active {
            color: $breadcrumbs-link-color--active;
        }
    }
}
