@keyframes exitUp {
    0% {
        transform: translateY(0);
        opacity: 1;
    }

    70% {
        transform: translateY(0);
        opacity: 1;
    }

    100% {
        transform: translateY(-200px);
        opacity: 0;
    }
}

.a-alert {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 200px;
    z-index: 99;
    font-size: $font-size-large;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: exitUp 4.5s $ease-circ-o;
    animation-fill-mode: both;

    &--success {
        background: $color__green;
        color: $color__white;
    }
}
