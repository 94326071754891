@if index($blocks, buttons) {
    .b-buttons {

        @for $i from 2 to 4 {
            &:nth-child(n+#{$i}) {
                @include mq("lg") {
                    animation-delay: $i * 0.15s;
                }
            }
        }

        &__wrapper {
            @apply flex items-center flex-wrap;


            &.utl-align--center {
                @apply justify-center;
            }

            @include mq("lg") {
            }
        }

        &__heading {
            @include mq("lg") {
                @apply px-20;
            }
        }

        &__button {}
        @include attention() {}
    }


    .js-animate--start > .b-buttons {
        @extend %js-animate--slide-down;
    }
}
