$ease-std: ease-in-out;
$ease-pop: cubic-bezier(0.68, -0.55, 0.265, 1.55);
$ease-pop-big: cubic-bezier(0.3, 0, 0, 1.3);

// Cubic
$ease-cubic-i: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$ease-cubic-o: cubic-bezier(0.215, 0.61, 0.355, 1);

// Circ
$ease-circ-i: cubic-bezier(0.6, 0.04, 0.98, 0.335);
$ease-circ-o: cubic-bezier(0.075, 0.82, 0.165, 1);

// Expo
$ease-expo-i: cubic-bezier(0.95, 0.05, 0.795, 0.035);
$ease-expo-o: cubic-bezier(0.19, 1, 0.22, 1);

// Quad
$ease-quad-i: cubic-bezier(0.55, 0.085, 0.68, 0.53);
$ease-quad-o: cubic-bezier(0.25, 0.46, 0.45, 0.94);

// Quart
$ease-quart-i: cubic-bezier(0.895, 0.03, 0.685, 0.22);
$ease-quart-o: cubic-bezier(0.165, 0.84, 0.44, 1);

$timing--default: 0.275s;
$easing--default: ease-in-out;
$transition--default: all $timing--default $easing--default;
