$tease-default-link-color: $color__black !default;
$tease-default-link-color--hover: $color__primary !default;

@if index($tease-types, default) {
    .a-tease--default {
        &__link {
            color: $tease-default-link-color;
        }

        &__image {}

        &__heading {
            @apply mt-3 mb-0;
            font-weight: $card-tease-heading-weight;
        }

        &__text {
            justify-content: space-between;
        }

        @include attention() {
            .a-tease--default__link {
                color: $tease-default-link-color--hover;
            }
        }
    }
}
