.b-buttons {
    text-decoration: none;

    @for $i from 2 to 4 {
        &:nth-child(n+#{$i}) {

        }
    }

    &__wrapper--outer {


    }

    &__wrapper {

    }

    &__heading {
    }

    &__button {}
    @include attention() {}
}

.b-buttons__wrapper--outer > h2 {
}

.js-animate--start > .b-buttons {
}
