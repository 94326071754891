.pull-quote {
    @apply px-24 flex-wrap;
    position: absolute;

    &__container {
        @apply -mx-24 relative;
    }
    &__wrapper {
        @apply flex flex-col;
        border-top: solid 20px RGBA(53,113,132,1);
        border-bottom: solid 20px RGBA(53,113,132,1);

        @include mq(xl) {
            @apply flex flex-row w-1/2;
            max-width: 500px;
        }
    }
    &__text {
        @apply flex flex-col;
        position: relative;
        color: $color__primary;
        font-family: $font__tertiary;
        font-weight: $font-weight-light;
        font-size: $font-size-h4;
        line-height: $line-height-xlarge;
    }

    &__content--text{
        @apply flex flex-col w-full;

        @include mq(lg) {
            @apply w-1/2;
        }
    }
}
