@if index($blocks, buttons) {
    .b-entries {
        @apply text-center;

        &__wrapper {}

        &__image-wrapper {
            transition: $transition--default;

            @include attention() {
            }
        }

        &__heading {}
    }
}
