$tease-image-block-text-color: $color__white !default;
$tease-image-block-background-color: rgba(0,0,0,0.65) !default;

@if index($tease-types, image-block) {
    .a-tease--image-block {
        &__link {}

        &__image {
            @include mq("md") {
                z-index: 1;
            }
        }

        &__text {
            @apply relative p-2;
            background-color: $tease-image-block-background-color;
            color: $tease-image-block-text-color;
            transition: $transition--default;
            text-decoration: none;

            @include mq($until: lg) {
                min-height: 76px;
            }

            @include mq("lg") {
                @apply pt-4 pb-4;
            }

            &.utl-has-image {
                @include mq("lg") {
                    @apply absolute;
                    left: ($tease-py / 2);
                    bottom: 0;
                    right: ($tease-py / 2);
                    z-index: 2;
                }
            }

            a {
                color: $color__white;
            }
        }

        &__heading {
            @apply my-0;
            color: $tease-image-block-text-color;
            font-size: 1.15rem;
        }

        &__details {
            @apply m-0;

            color: $tease-image-block-text-color;
            font-size: 1rem;
        }
    }
}
