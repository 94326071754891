.banner {
    background: #15171E;
    overflow: hidden;

    &.banner--small {

        @include mq($from: lg, $until: 1799px) {
        }

        @include mq($from: 1800px) {
        }
    }

    &.banner--standard {
        @include mq($from: lg, $until: 1799px) {
        }

        @include mq($from: 1800px) {
        }
    }

    &.banner--large {
        overflow: visible;
        position: relative;

        @include mq($until: sm) {
            margin-bottom: 380px;
        }

        @media (max-width: 520px) {
            margin-bottom: 350px;
        }

        @media (max-width: 400px) {
            margin-bottom: 500px;
        }

        @media (max-width: 350px) {
            margin-bottom: 600px;
        }

        @media (max-width: 300px) {
            margin-bottom: 800px;
        }

        @include mq("md") {
            @apply mb-4;
        }

        @include mq("lg") {
            @apply mb-24;
        }


        @include mq($from: lg, $until: 1799px) {
        }

        @include mq($from: 1800px) {
        }
    }

    &__links {

        @include mq(md) {

        }

    }

    &__text {
        position: relative;
        z-index: 2;
        max-width: 630px;
        transform: translateY(-50px);

        @include mq($until: md) {
            transform: none;
            top: inherit;
            margin-top: -270px;
        }

        @media (max-width: 400px) {
            margin-top: -300px;
        }

        @media (max-width: 350px) {
            margin-top: -420px;
        }

        @media (max-width: 300px) {
            margin-top: -350px;
        }

        &.banner__text--center {
        }

        &.banner__text--right {
        }

        &__heading {
            @apply pl-0;

            font-weight: 700;
            font-size: $font-size-banner-heading-min;
            font-family: 'Jost', sans-serif;
            line-height: $line-height-small;
            background: transparent;
            font-size: 3rem;
            line-height: 1.1;
            @apply mb-2;

            @include mq("md") {
                font-size: 5rem;
                @apply mb-8;
            }
        }

        &__description {
            @apply pt-8 px-8 pt-4 mb-0;
            background: $color__secondary--2;
            font-weight: $font-weight-bold;

            @include mq("md") {
                @apply pt-12 px-16 pt-8 mb-0;
            }
        }

    }

    &__links {
        @apply mt-0 mb-24 mx-0 pt-8 pb-12 px-16 block flex justify-end;
        background: $color__secondary--2;

        @include mq("md") {
            @apply mb-0;
        }

        > .a-button--primary {
            @apply mt-0;
        }
    }

    &__image {

        @include mq($until: md) {
            position: relative;
            transform: none;
            top: inherit;
        }

        @include mq($until: lg) {
            > picture {

                > img {
                }
            }
        }
    }
}


@import 'banner-options/banner--slider';
@import 'banner-options/banner--video';
@import 'banner-options/banner--image';
