$skip-link-color: $color__white !default;
$skip-link-background: $color__primary !default;
$skip-link-hover-background: $color__secondary !default;
$skip-link-hover-colour: $color__primary !default;
$skip-link-padding: 10px 16px !default;

.skip-link {
  @extend %visually-hidden;

  position: fixed;
  top: 10px;
  left: 10px;
  color: $skip-link-color;
  background: $skip-link-background;
  opacity: 0;
  text-decoration: none;
  transition: all $timing--default $ease-std;
  z-index: 200;
  outline: none;
  padding: $skip-link-padding;

  &:focus {
    color: $skip-link-color;
    overflow: visible;
    width: auto;
    height: auto;
    opacity: 1;
    clip: auto;
    white-space: normal;
    outline: none;
  }

  &:hover {
    color: $skip-link-hover-colour;
    background: $skip-link-hover-background;
  }
}
