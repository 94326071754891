.js .b-shutters__shutter__content {
}

.b-shutters {
    margin-bottom: $paragraph-spacing;

    &__shutter {
        &__title {
            > svg {
            }

            @include attention() {
                > svg {
                }
            }

            &.utl-is-active {
                > svg {
                }

                + .shutters__shutter__content {

                }
            }
        }

        &__content {
            &__inner {
            }
        }

    }
}
