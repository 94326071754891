.a-logo {
    &__wrapper {
        @apply flex h-full content-center;

        padding: 8px 0;
        max-width: 280px;

        @include mq("lg") {
            padding: 10px 0;
        }
    }

    &__link {
        @apply w-full;
    }

    &__image {
        object-fit: contain;
        height: ( $header-primary-height-mobile - 10px );
        transition: height 0.2s ease-in-out;

        @include mq($from:lg, $until:xxl) {
            height: ( $header-primary-height-tablet - 20px );
        }

        @include mq("xxl") {
            height: ( $header-primary-height-desktop - 20px );
        }
    }
}
