.c-prevNext {
    @apply pt-0;

    &__link {
        &--left {
        }
        &--right {
        }
    }
    &__icon {
        color: $color__secondary;
        min-width: 25px;

        &--left {
        }
        &--right {
        }
    }
}
