.cases {
    @apply w-full;
    padding: 1rem;
    position: relative;
    align-items: right;
    &__wrapper {
        @apply flex;
    }
    &__text{
        padding-bottom: 2rem;
    }
    &__button {
        padding: 0.5rem;
        color: #fff;
        text-align: right;
        border-bottom: 8px solid $color__primary;
        width: 40%;
        @include mq(md){
            width: 50%;
        }
        &--link{
          color: $color__grey--100;
          letter-spacing: 6px;
        }
    }
    @include mq(lg) {
        @apply w-1/2;

    }
}
