@if index($tease-types, image-block) {
    .a-tease--image-block {
        &__link {}

        &__image {}

        &__heading {}

        &__details {}

        &__text {}

        @include attention() {
            .a-tease--image-block__text {
                @include mq("lg") {
                    @apply pt-5 pb-5;
                }
            }
        }
    }
}
