@if index($banner-style, slider) {
    .banner--slider > .swiper-wrapper {
        @include mq($until: 1799px) {
          position: absolute !important;
        }
    }
}

@include mq($until:sm) {
    .swiper-button-prev, .swiper-container-rtl .swiper-button-next {
        left: 5px !important;
    }

    .swiper-button-next, .swiper-container-rtl .swiper-button-prev {
        right: 5px !important;
    }

    .swiper-button-next:after, .swiper-button-prev:after {
        font-size: 2rem !important;
    }
}

@include mq($until:375px) {
    .swiper-button-prev, .swiper-container-rtl .swiper-button-next {
        left: 0px !important;
    }

    .swiper-button-next, .swiper-container-rtl .swiper-button-prev {
        right: 0px !important;
    }

    .swiper-button-next:after, .swiper-button-prev:after {
        font-size: 1.75rem !important;
    }
}


@keyframes heroScale {
    0% {
        opacity: 0;
        transform: scale(1);
    }
    100% {
        opacity: 1;
        transform: scale(1.1);
    }
}

.banner--large .swiper-slide .loaded {
    animation: heroScale 2s ease-out;
    animation-fill-mode: forwards;
    position: relative;
    z-index: 1;
}

.banner--slider__image picture {
    overflow: hidden;
    position: relative;
    z-index: 1;
}

@keyframes heroText {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes heroLink {
    0% {
        opacity: 0;
        transform: translateX(-20px);
    }
    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}

.banner--large .banner--slider__text__heading,
.banner--large .banner--slider__text__description,
.banner--large .banner__links {
    opacity: 0;
}

.no-js .banner--slider__text__heading,
.no-js .banner--large .banner--slider__text__description,
.no-js .banner--large .banner__links {
    opacity: 1;
}

.banner--large .swiper-slide .banner--slider__text__heading {
    animation: heroText 0.5s ease-out;
    animation-fill-mode: forwards;
    position: relative;
    z-index: 1;
    animation-delay: 0.5s;
}

.banner--large .swiper-slide .banner--slider__text__description {
    animation: heroText 0.5s ease-out;
    animation-fill-mode: forwards;
    position: relative;
    z-index: 1;
    animation-delay: 0.75s;
}

.banner--large .swiper-slide .banner__links {
    animation: heroLink 0.5s ease-out;
    animation-fill-mode: forwards;
    position: relative;
    z-index: 1;
    animation-delay: 1s;
}
