.b-images {
    @apply mt-8 mb-2;

    &--default {
    }

  &__image {
      &--default {
      }
  }
}
