// Variables
$hamburger-size: 50px !default;
$hamburger-thickness: 2px !default;
$hamburger-color: #000 !default;
$hamburger-color-active: $hamburger-color !default;
$hamburger-background: transparent !default;
$hamburger-background-active: $hamburger-background !default;
$hamburger-border-color: transparent !default;
$hamburger-border-width: 0 !default;
$hamburger-opacity: 1 !default;
$hamburger-opacity-hover: 1 !default;
$hamburger-padding: $hamburger-size / 10 !default;
$hamburger-bar-spacing: $hamburger-size / 5 !default;
$hamburger-animation-speed: 1 !default;
$hamburger-border-radius: 0 !default;

// Animation types. Use this as a variable and remove the animations
// you don't need so they won't get compiled in your css.
$hamburger-animations: (
    collapse,
    converge,
    criss-cross,
    default,
    minimal,
    stack,
    twist,
    scissor,
    scissor-alt,
    minus
)
!default;

$hamburger-bar-size: $hamburger-size - ($hamburger-padding * 2) - ($hamburger-border-width * 2);
$hamburger-easing: cubic-bezier(0.645, 0.045, 0.355, 1);

// "Constants"
$ROOT-TWO: 1.41421356237309504880;

// Hamburger base
@mixin hamburger-bar() {
    content: "";
    width: 100%;
    height: $hamburger-thickness;
    display: block;
    position: absolute;
    background-color: $hamburger-color;
    border-radius: $hamburger-border-radius;
}

button.a-nav-toggle {
    width: $hamburger-size;
    height: $hamburger-size;
}

.a-nav-toggle {
    background: $hamburger-background;
    border: $hamburger-border-width $hamburger-border-color solid;
    padding: $hamburger-padding;
    cursor: pointer;
    font: inherit;
    color: inherit;
    text-transform: none;
    margin: 0;
    overflow: visible;
    opacity: $hamburger-opacity;
    transition: opacity 0.2s $hamburger-easing, background 0.2s $hamburger-easing;

    &.utl-is-active {
        background: $hamburger-background-active;

        span.bar {
            background-color: $hamburger-color-active;
        }
    }

    .inner {
        position: relative;
        display: block;
        margin: 0;
        width: 100%; //$hamburger-bar-size;
        margin-top: -$hamburger-thickness / 2;
    }

    span.bar {
        @include hamburger-bar();

        &:nth-child(1) {
            top: -$hamburger-bar-spacing;
        }

        &:nth-child(3) {
            top: $hamburger-bar-spacing;
        }
    }

    &:focus {
        outline: 0;
    }

    &:hover {
        opacity: $hamburger-opacity-hover;
    }
}

@import 'animations/default';
@import 'animations/criss-cross';
@import 'animations/converge';
@import 'animations/stack';
@import 'animations/minus';
@import 'animations/twist';
@import 'animations/collapse';
@import 'animations/scissor';
@import 'animations/scissor-alt';
@import 'animations/minus';
