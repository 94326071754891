$pagination-m: 1.5em 0 !default;
$pagination-link-p: 23px 33px !default;
$pagination-link-bg: $color__grey !default;
$pagination-link-color: $color__black !default;
$pagination-link-bg--hover: $color__primary !default;
$pagination-link-color--hover: $color__white !default;
$pagination-link-bg--current: $color__primary !default;
$pagination-link-color--current: $color__white !default;
$pagination-link-spacing: 1px !default;
$pagination-alignment: (
    left,
    center,
    right
)
!default;
$pagination-link-transition: $transition--default !default;

.c-pagination {
    margin: $pagination-m;

    > .current,
    > a {
        @apply inline-block no-underline;

        padding: $pagination-link-p;
        margin-right: $pagination-link-spacing;

        &:last-child {
            margin-right: 0;
        }
    }

    > a {
        color: $pagination-link-color;
        background-color: $pagination-link-bg;
        transition: $pagination-link-transition;

        @include attention() {
            color: $pagination-link-color--hover;
            background-color: $pagination-link-bg--hover;
        }
    }

    > .current {
        color: $pagination-link-color--current;
        background-color: $pagination-link-bg--current;
    }
}

@if index($pagination-alignment, center) {
    .c-pagination {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
}

@if index($pagination-alignment, right) {
    .c-pagination {
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
    }
}
