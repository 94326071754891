@if index($blocks, image-text) {
    .b-imageText {

        @include mq("lg") {
            @apply flex;
        }

        &__image,
        &__text {
            @include mq("lg") {
                @apply w-1/2;
            }
        }

        &__image {
          img {
            @apply w-full h-full;

            object-fit: cover;
            object-position: center;
          }
        }

        &__text {
            @apply p-4;

            @include mq("lg") {
                @apply flex flex-col justify-center p-16;
            }

            > *:first-child {
                margin-top: 0;
            }
        }

        &.utl-position--right {
            @include mq("lg") {
                @apply flex-row-reverse;
            }
        }
    }
}
