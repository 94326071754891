@mixin iconSizes($from: 4, $to: 128){
  @for $i from $from to $to{
      &--#{$i} {
          > svg {
              width: $i + px;
              height: $i + px;
          }
      }
  }
}

.a-icon__wrapper {
    > svg {
        display: inline;
    }

     @include iconSizes($from: 4, $to: 128);
}
