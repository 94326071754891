.a-form {
    &--search {
        @apply flex;

        > input {
            margin: 0;
        }

        .a-form__label {
            width: 100%;
            padding: 0;

            > input {
                margin: 0;
                border-radius: 5px 0 0 5px;

            }
        }
    }
}

form {
    max-width: 800px;
}

 label {
    display: inline-block;
    padding-bottom: 0.5rem;
}

input, textarea {
    display: block;
    width: 100%;
    border: 1px solid #888;
    font-family: $font-family-default;
    color: $text-color;
    background: $color__white;
    transition: 0.3s ease-in-out border-color;
    border-radius: 2px;
    font-size: 1.3rem;
    padding: 15px 20px;
    margin-bottom: 1rem;

    &:focus {
        outline: none;
        border-color: $color__primary;
    }
}

.input > div {
    @include mq("md") {
        display: flex;
    }
}

select {
    height: 64px;
    border: 1px solid #888;
    margin-bottom: 1rem;

    @include mq($until: md) {
      width: 100%;
    }

    @include mq("lg") {
        height: 67px;
    }

    @include mq("xl") {
        height: 72px;
    }
}

.submit {
    margin-left: -10px;
}

.alert {
    @apply p-4 mb-8;
}

.alert--success {
    background-color: #88C695;
}

.alert--success {
    background-color: #88C695;
}

.alert--danger {
    color: #e01d00;
    background-color: #fcefed;
}

.fieldset--spaced {
    @apply my-16;
}

.fieldset--grid,
.field {
    @include mq("sm") {
        display: grid;
        grid-template-columns: 25% 1fr;
        grid-column-gap: 16px;
    }
}

legend {
    @apply my-4;
    font-size: $font-size-h6;
    text-transform: uppercase;
    color: #7f7f7f;
}

label,
.heading > label {
    align-self: center;
}

label,
.radio__legend,
.field > .heading {
    @include mq("sm") {
        grid-column: 1 / 2;
    }
}

input,
button,
.align-input,
#gRecaptchaContainer,
.field > .input {
    @include mq("sm") {
        grid-column: 2 / 3;
    }
}

#gRecaptchaContainer {
    @apply mb-8;
}

.radio__legend {
    @apply mb-0;
}

.radio__label {
    @apply flex mb-0;
}

.radio__input {
    @apply mb-0;

    width: 30px;
    height:30px;
}

.input--submit {
    border: none;
    width: initial;
}

.utl-required {
    color: #7f7f7f;
}

.submit {
    @include mq("sm") {
        margin-left: 25%;
    }
}

.card-data {
    @apply mt-6;
}
