$page-max-width: 2200px !default;
$container-width: 86.5% !default;
$container-max-width: 1640px !default;
$content-max-width: ($container-max-width - 200px ) !default;
$sidebar-width: calc(3vw + 200px) !default;
$header-max-width: 100% !default;
$navbar-max-width: $page-max-width !default;
$header-primary-height-mobile: 80px !default;
$header-primary-height-tablet: 100px !default;
$header-primary-height-desktop: 120px !default;
$header-offset-mobile: $header-primary-height !default;
$header-offset-tablet: ( $header-primary-height-tablet + $header-secondary-height-tablet ) !default;
$header-offset-desktop: ( $header-primary-height-desktop + $header-secondary-height-desktop ) !default;

html {
    @media screen {
        background-color: $color__page-background;
    }
}

body {
    background-color: $color__secondary--4;
    max-width: $page-max-width;
    margin: 0 auto;
}

.container, %container {
    width: $container-width;
    max-width: $container-max-width;
    @apply mx-auto;

    @include mq("lg") {
        width: $container-width * 0.9248554913;
    }

    &--header {
        max-width: $header-max-width;
        @apply mx-auto;
    }

    &--navbar {
        @apply mx-auto;

        &:not(.navbar--centre) {
            width: $container-width;
            max-width: $navbar-max-width;
        }
    }

    &--content {
        max-width: $content-max-width;
    }
}

.o-page-wrap {
    position: relative;
}

.o-header-offset {
    padding-top: 65px;

    @include mq($from:lg, $until:xxl) {
        padding-top: $header-offset-tablet;
    }

    @include mq("xxl") {
      padding-top: $header-offset-desktop;
    }
}

.o-content{
    &__wrapper {
        @include mq("lg") {
            @apply relative flex;
        }
    }

    &__content {
        @apply pb-10;

        max-width: 100%;

        @include mq("lg") {
             @apply flex-grow;
        }

        &--has-sidebar {
        }
    }

    &__sidebar {
        @apply block;

        @include mq("lg") {
          @apply inline relative pl-8;

          top: 0;
          min-width: $sidebar-width;
          right: 0;
        }
    }
}

#main-content {
    overflow: hidden;
}
