@charset "UTF-8";
@tailwind base;
@tailwind utilities;
html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

::selection {
  color: #fff;
  background: #5194A9;
  /* WebKit/Blink Browsers */
  text-shadow: none;
}

.skip-link, .visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: visible;
  clip: rect(1px, 1px, 1px, 1px);
  border: 0;
}

.utl-reversed {
  transform: scaleX(-1);
}

table {
  @apply mb-8 text-left;
}

td,
th {
  padding: 15px;
  border-bottom: 10px solid #fff;
  text-align: left;
}
td > p,
th > p {
  line-height: 1.3;
  margin-bottom: 0;
}

th {
  background: #357184;
  color: #fff;
}
th + th {
  border-left: 10px solid #fff;
}

td {
  background: #efefef;
  color: #000;
  background: #efefef;
}
@media (min-width: 62em) {
  td {
    min-width: 150px;
  }
}
td + td {
  border-left: 10px solid #fff;
}

html {
  font-family: europa, sans-serif;
  font-size: 18px;
  line-height: 1.5;
  font-weight: 400;
  color: #fff;
}
@media (min-width: 36em) and (max-width: 61.99em) {
  html {
    font-size: 16.2px;
  }
}
@media (min-width: 62em) and (max-width: 89.99em) {
  html {
    font-size: 16.2px;
  }
}
@media (min-width: 90em) and (max-width: 112.49em) {
  html {
    font-size: 18px;
  }
}
@media (min-width: 112.5em) and (max-width: 123.74em) {
  html {
    font-size: 18px;
  }
}
@media (min-width: 123.75em) {
  html {
    font-size: 19.8px;
  }
}

.h1,
h1 {
  @apply mt-8 mb-8 p-8;
  font-weight: 700;
  font-family: europa, sans-serif;
  line-height: 1.2;
  background-color: #2F3444;
  font-size: 2.632em;
}

.h2,
h2 {
  @apply mt-8 mb-8;
  font-weight: 700;
  font-family: europa, sans-serif;
  line-height: 1.2;
  font-size: 2.24em;
}

.h3,
h3 {
  @apply mt-8 mb-8;
  font-family: europa, sans-serif;
  line-height: 1.2;
  font-size: 1.906em;
}

.h4,
h4 {
  @apply mt-8 mb-8;
  font-family: europa, sans-serif;
  line-height: 1.2;
  font-size: 1.622em;
}

.h5,
h5 {
  @apply mt-8 mb-8;
  font-family: europa, sans-serif;
  line-height: 1.2;
  font-size: 1.381em;
}

.h6,
h6 {
  @apply mt-8 mb-8;
  font-family: europa, sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.25px;
  line-height: 1.2;
  font-size: 1.175em;
}

p {
  margin-bottom: 2rem;
  line-height: 1.5;
}
.two-columns p:last-of-type {
  margin-bottom: 0.2px;
}

a {
  color: #5194A9;
  transition: color 0.275s linear;
}
a:hover, a:active, a:focus {
  color: #68739a;
}

ul li {
  line-height: 1.65;
  list-style: none;
}

@media (min-width: 48em) {
  .two-columns {
    column-count: 2;
    column-gap: 100px;
  }
  .two-columns > *:first-child {
    margin-top: 0;
  }
}

q {
  quotes: "“" "";
}
q::before {
  content: open-quote;
}

blockquote {
  @apply my-8;
  font-family: europa, sans-serif;
  font-style: italic;
  font-size: 2rem;
  color: #696969;
  line-height: 1.1;
  padding-left: 1em;
  border-left: 4px #696969 solid;
}

hr {
  @apply p-0 mt-4 mb-10;
  border-top: 1px solid #5194A9;
}

figcaption {
  @apply my-4;
  color: #4E4E4E;
  font-size: 0.9rem;
  font-style: italic;
}

.strong,
strong {
  font-weight: 700;
}

.underlined--centered,
.underlined {
  @apply relative;
  padding-bottom: 25px;
}
.underlined--centered::after,
.underlined::after {
  @apply absolute;
  content: "";
  bottom: 0;
  left: 0;
  height: 2px;
  width: 50px;
  background: #696969;
}

.underlined--centered::after {
  left: 50%;
  margin-left: -25px;
}

.lead {
  font-weight: 700;
}

.small {
  font-size: 0.8em;
}

.o-content__content ul:not(.list--unstyled),
.o-content__content ol:not(.list--unstyled) {
  line-height: 1.65;
  margin-bottom: 2rem;
  margin-left: 1em;
}
.o-content__content ul:not(.list--unstyled) {
  list-style-type: disc;
}
.o-content__content ul:not(.list--unstyled) > li > ul {
  list-style-type: circle;
}
.o-content__content ul:not(.list--unstyled) > li > ul > li > ul {
  list-style-type: square;
}
.o-content__content ol:not(.list--unstyled) {
  list-style-type: decimal;
}
.o-content__content ol:not(.list--unstyled) > li > ol {
  list-style-type: lower-alpha;
}
.o-content__content ol:not(.list--unstyled) > li > ol > li > ol {
  list-style-type: lower-roman;
}
.o-content__content .list--unstyled {
  list-style: none;
}

dl {
  @apply my-4;
  overflow: hidden;
  padding: 0;
}

dt {
  float: left;
  width: 50%;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 13px 0;
  margin: 0;
}
dt:first-of-type {
  border-top: none;
}

dd {
  float: left;
  width: 50%;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 13px 0;
  margin: 0;
}
dd:first-of-type {
  border-top: none;
}

.utl-highlighted {
  padding: 0px 6px 2px 6px;
  background-color: #68739a;
}

@media screen {
  html {
    background-color: #3d4150;
  }
}

body {
  background-color: #15171E;
  max-width: 2200px;
  margin: 0 auto;
}

.container {
  width: 86.5%;
  max-width: 1640px;
  @apply mx-auto;
}
@media (min-width: 62em) {
  .container {
    width: 79.9999999975%;
  }
}
.container--header {
  max-width: 2200px;
  @apply mx-auto;
}
.container--navbar {
  @apply mx-auto;
}
.container--navbar:not(.navbar--centre) {
  width: 86.5%;
  max-width: 2120px;
}
.container--content {
  max-width: 1340px;
}

.o-page-wrap {
  position: relative;
}

.o-header-offset {
  padding-top: 65px;
}
@media (min-width: 62em) and (max-width: 112.49em) {
  .o-header-offset {
    padding-top: 110px;
  }
}
@media (min-width: 112.5em) {
  .o-header-offset {
    padding-top: 150px;
  }
}

@media (min-width: 62em) {
  .o-content__wrapper {
    @apply relative flex;
  }
}
.o-content__content {
  @apply pb-10;
  max-width: 100%;
}
@media (min-width: 62em) {
  .o-content__content {
    @apply flex-grow;
  }
}
.o-content__sidebar {
  @apply block;
}
@media (min-width: 62em) {
  .o-content__sidebar {
    @apply inline relative pl-8;
    top: 0;
    min-width: calc(3vw + 300px);
    right: 0;
  }
}

#main-content {
  overflow: hidden;
}

.c-breadcrumbs {
  @apply inline-block mt-6;
}
@media (min-width: 75em) {
  .c-breadcrumbs {
    @apply mt-16;
  }
}
.c-breadcrumbs__item {
  display: inline;
}
.c-breadcrumbs__item + .c-breadcrumbs__item:before {
  content: "/";
  padding: 0 7px 0 3px;
  color: #4E4E4E;
}
.c-breadcrumbs__link {
  color: #5194A9;
}
.c-breadcrumbs__link:focus, .c-breadcrumbs__link:hover {
  color: #357184;
}
.c-breadcrumbs__link.utl-is-active {
  color: #68739a;
}

.c-pagination {
  margin: 1.5em 0;
}
.c-pagination > .current,
.c-pagination > a {
  @apply inline-block no-underline;
  padding: 23px 33px;
  margin-right: 1px;
}
.c-pagination > .current:last-child,
.c-pagination > a:last-child {
  margin-right: 0;
}
.c-pagination > a {
  color: #000;
  background-color: #efefef;
  transition: all 0.275s ease-in-out;
}
.c-pagination > a:hover, .c-pagination > a:active, .c-pagination > a:focus {
  color: #fff;
  background-color: #5194A9;
}
.c-pagination > .current {
  color: #fff;
  background-color: #5194A9;
}

.c-pagination {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.c-prevNext {
  @apply py-12;
}
.c-prevNext__link {
  @apply flex;
}
@media (min-width: 48em) {
  .c-prevNext__link {
    @apply w-1/2;
  }
}
@media (min-width: 48em) {
  .c-prevNext__link--left {
    @apply float-left;
  }
}
.c-prevNext__link--right {
  @apply justify-end;
}
@media (min-width: 48em) {
  .c-prevNext__link--right {
    @apply float-right;
  }
}
.c-prevNext__icon {
  @apply my-2;
}
.c-prevNext__icon--left {
  @apply mr-4;
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
}
.c-prevNext__icon--right {
  @apply ml-4;
}

.skip-link {
  position: fixed;
  top: 10px;
  left: 10px;
  color: #fff;
  background: #5194A9;
  opacity: 0;
  text-decoration: none;
  transition: all 0.275s ease-in-out;
  z-index: 200;
  outline: none;
  padding: 10px 16px;
}
.skip-link:focus {
  color: #fff;
  overflow: visible;
  width: auto;
  height: auto;
  opacity: 1;
  clip: auto;
  white-space: normal;
  outline: none;
}
.skip-link:hover {
  color: #5194A9;
  background: #68739a;
}

.banner {
  @apply relative overflow-hidden bg-cover;
  background-color: #050406;
}
.banner.banner--small {
  height: 275px;
}
@media (min-width: 62em) and (max-width: 112.4375em) {
  .banner.banner--small {
    height: 0;
    padding-bottom: 22% !important;
  }
}
@media (min-width: 112.5em) {
  .banner.banner--small {
    height: calc(50vh - 247px);
    min-height: 300px;
    max-height: 440px;
  }
}
.banner.banner--standard {
  height: 400px;
}
@media (min-width: 62em) and (max-width: 112.4375em) {
  .banner.banner--standard {
    height: 0;
    padding-bottom: 33.3% !important;
  }
}
@media (min-width: 112.5em) {
  .banner.banner--standard {
    height: calc(70vh - 247px);
    min-height: 400px;
    max-height: 640px;
  }
}
.banner.banner--large {
  height: 500px;
}
@media (min-width: 62em) and (max-width: 112.4375em) {
  .banner.banner--large {
    height: 0;
  }
}
@media (min-width: 62em) and (max-width: 89.99em) {
  .banner.banner--large {
    margin-bottom: 98px;
    padding-bottom: 43% !important;
  }
}
@media (min-width: 90em) and (max-width: 112.4375em) {
  .banner.banner--large {
    margin-bottom: 70px;
    padding-bottom: 45.5% !important;
  }
}
@media (min-width: 112.5em) {
  .banner.banner--large {
    height: calc(100vh - 247px);
    min-height: 600px;
    max-height: 713px;
    margin-bottom: 190px;
  }
}
.banner__text {
  @apply absolute z-10 w-full;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
  font-size: 1.2rem;
}
.banner__text.banner__text--left {
  max-width: 750px;
}
.banner__text.banner__text--center {
  @apply text-center;
}
.banner__text.banner__text--right {
  @apply text-right;
  max-width: 750px;
  right: 0;
}
.banner__text__heading {
  @apply mb-2;
  color: #fff;
  text-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
}
.banner__text__description {
  text-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
}
.banner__image {
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  width: 100%;
}
@media (max-width: 61.99em) {
  .banner__image {
    height: 100%;
  }
  .banner__image > picture {
    width: 100%;
    height: 100%;
    display: flex;
  }
  .banner__image > picture > img {
    object-fit: cover;
    height: auto;
    width: 100%;
  }
}
.banner__links {
  @apply mt-6;
  margin-left: -10px;
  margin-right: -10px;
}

.no-js .banner__image > picture {
  display: none;
}

@media (max-width: 112.4375em) {
  .banner--slider > .swiper-wrapper {
    position: absolute !important;
  }
}

.c-tease-listing {
  margin-top: 0;
}
@media (min-width: 36em) {
  .c-tease-listing {
    margin-left: -15px;
    margin-right: -15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}

.a-tease {
  position: relative;
  margin-top: 30px;
  min-height: 90px;
}
@media (min-width: 36em) {
  .a-tease {
    padding-left: 15px;
    padding-right: 15px;
    flex-basis: 50%;
  }
}
@media (min-width: 62em) {
  .a-tease {
    flex-basis: 33.33%;
  }
}
.a-tease__link {
  text-decoration: none;
}
.a-tease__details {
  @apply mt-2 mb-3;
  font-size: 0.9em;
}
.a-tease__details__date {
  @apply block;
}
.a-tease__description {
  @apply block my-4;
}
.a-tease__author {
  font-weight: 700;
}
.a-tease__categories__category {
  font-weight: 700;
}
.a-tease__cta {
  font-weight: 700;
}
.a-tease__cta--chevron__icon {
  @apply ml-2;
  transition: margin-left 0.225s ease-in-out;
}
.a-tease__cta--chevron__icon > svg {
  height: 20px;
  width: 20px;
  margin-top: -2px;
}
.a-tease__cta--button {
  margin-left: 0 !important;
  background-color: #848484;
}
.a-tease__cta--button__text {
  color: #fff;
}
.a-tease:hover .a-tease__cta--button, .a-tease:active .a-tease__cta--button, .a-tease:focus .a-tease__cta--button {
  background-color: #696969;
}
.a-tease:hover .a-tease__cta--chevron__icon, .a-tease:active .a-tease__cta--chevron__icon, .a-tease:focus .a-tease__cta--chevron__icon {
  margin-left: 0.65rem;
}

@media (min-width: 62em) {
  .c-tease-listing--1 > .a-tease {
    flex-basis: 100%;
  }
}

@media (min-width: 62em) {
  .c-tease-listing--2 > .a-tease {
    flex-basis: 50%;
  }
}

@media (min-width: 62em) {
  .c-tease-listing--4 > .a-tease {
    flex-basis: 25%;
  }
}

.a-tease--default__link {
  color: #000;
}
.a-tease--default__heading {
  @apply mt-3 mb-0;
  font-weight: 700;
}
.a-tease--default__text {
  justify-content: space-between;
}
.a-tease--default:hover .a-tease--default__link, .a-tease--default:active .a-tease--default__link, .a-tease--default:focus .a-tease--default__link {
  color: #5194A9;
}

.a-tease--card__heading {
  @apply mt-3 mb-0;
  font-weight: 700;
}
.a-tease--card__text {
  @apply py-4 px-8 w-full;
  justify-content: space-between;
  background-color: #efefef;
}

@media (min-width: 48em) {
  .a-tease--image-block__image {
    z-index: 1;
  }
}
.a-tease--image-block__text {
  @apply relative p-2;
  background-color: rgba(0, 0, 0, 0.65);
  color: #fff;
  transition: all 0.275s ease-in-out;
  text-decoration: none;
}
@media (max-width: 61.99em) {
  .a-tease--image-block__text {
    min-height: 76px;
  }
}
@media (min-width: 62em) {
  .a-tease--image-block__text {
    @apply pt-4 pb-4;
  }
}
@media (min-width: 62em) {
  .a-tease--image-block__text.utl-has-image {
    @apply absolute;
    left: 15px;
    bottom: 0;
    right: 15px;
    z-index: 2;
  }
}
.a-tease--image-block__text a {
  color: #fff;
}
.a-tease--image-block__heading {
  @apply my-0;
  color: #fff;
  font-size: 1.15rem;
}
.a-tease--image-block__details {
  @apply m-0;
  color: #fff;
  font-size: 1rem;
}

@media (min-width: 75em) {
  .a-tease--list {
    flex-basis: 100%;
  }
}
@media (min-width: 75em) {
  .a-tease--list > .a-tease__link {
    display: flex;
  }
}
@media (min-width: 75em) {
  .a-tease--list__image-wrapper {
    flex-basis: 100%;
  }
}
.a-tease--list__heading {
  @apply mt-3 mb-0;
  font-weight: 700;
}
.a-tease--list__text {
  @apply py-4 px-8 w-full;
  justify-content: space-between;
  background-color: #efefef;
}

button.a-nav-toggle {
  width: 50px;
  height: 50px;
}

.a-nav-toggle {
  background: transparent;
  border: 0 transparent solid;
  padding: 5px;
  cursor: pointer;
  font: inherit;
  color: inherit;
  text-transform: none;
  margin: 0;
  overflow: visible;
  opacity: 1;
  transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.a-nav-toggle.utl-is-active {
  background: transparent;
}
.a-nav-toggle.utl-is-active span.bar {
  background-color: #357184;
}
.a-nav-toggle .inner {
  position: relative;
  display: block;
  margin: 0;
  width: 100%;
  margin-top: -1px;
}
.a-nav-toggle span.bar {
  content: "";
  width: 100%;
  height: 2px;
  display: block;
  position: absolute;
  background-color: #357184;
  border-radius: 0;
}
.a-nav-toggle span.bar:nth-child(1) {
  top: -10px;
}
.a-nav-toggle span.bar:nth-child(3) {
  top: 10px;
}
.a-nav-toggle:focus {
  outline: 0;
}
.a-nav-toggle:hover {
  opacity: 1;
}

.a-nav-toggle--collapse span.bar {
  transition: transform 0.2666666667s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.1666666667s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1666666667s, opacity 0.1666666667s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1666666667s;
}
.a-nav-toggle--collapse.utl-is-active span.bar {
  top: 0;
  transition: transform 0.2666666667s cubic-bezier(0.645, 0.045, 0.355, 1) 0.0666666667s, top 0.1666666667s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.1666666667s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.a-nav-toggle--collapse.utl-is-active span.bar:nth-child(1) {
  transform: rotate(135deg);
}
.a-nav-toggle--collapse.utl-is-active span.bar:nth-child(2) {
  opacity: 0;
}
.a-nav-toggle--collapse.utl-is-active span.bar:nth-child(3) {
  transform: rotate(45deg);
}

@media (min-width: 62em) {
  .a-tease--image-block:hover .a-tease--image-block__text, .a-tease--image-block:active .a-tease--image-block__text, .a-tease--image-block:focus .a-tease--image-block__text {
    @apply pt-5 pb-5;
  }
}

.c-header__wrapper {
  @apply fixed w-full z-30;
  top: 0;
  left: 0;
}
@media (min-width: 75em) {
  .c-header__wrapper {
    transform: translateY(0);
    transition: transform 0.275s ease-in-out;
  }
}
@media (min-width: 62em) {
  .c-header__wrapper.headroom--not-top .a-logo__image {
    height: 90px;
  }
}
.c-header__wrapper.headroom--unpinned {
  position: fixed;
  left: 0;
}
@media (min-width: 75em) {
  .c-header__wrapper.headroom--unpinned {
    transform: translateY(0);
  }
}
@media (min-width: 112.5em) {
  .c-header__wrapper.headroom--unpinned {
    transform: translateY(0);
  }
}
.c-header--primary {
  @apply relative;
  background-color: #fff;
}
.c-header--primary:after {
  content: "";
  @apply absolute;
  width: calc(100% - 60px);
  left: 30px;
  margin-top: -40px;
  height: 40px;
  z-index: -1;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.015), 0px 0px 20px rgba(0, 0, 0, 0.035), 0px 0px 90px rgba(0, 0, 0, 0.075);
}
.c-header--secondary {
  @apply absolute;
}
@media (min-width: 75em) {
  .c-header--secondary {
    @apply relative block;
    height: 0;
    background-color: #68739a;
  }
}
@media (min-width: 75em) and (min-width: 112.5em) {
  .c-header--secondary {
    height: 0;
  }
}
.c-header--secondary.visible--mobile {
  @apply block;
}

.a-nav-toggle {
  @apply block self-center;
}
@media (min-width: 62em) {
  .a-nav-toggle {
    margin-right: 8%;
  }
}
@media (min-width: 75em) {
  .a-nav-toggle {
    @apply hidden;
  }
}

.c-nav--primary {
  @apply w-full items-center;
}
.c-nav--primary__wrapper {
  @apply flex flex-grow flex-wrap;
}
.c-nav--primary__list {
  @apply flex justify-end list-none;
}
.c-nav--primary__list-item {
  @apply relative flex;
  padding: 0 9px;
}
.c-nav--primary__list-item-link {
  @apply relative self-center;
  font-size: 1.1em;
  color: #4E4E4E;
  padding: 10px 9px 15px;
  text-decoration: none;
}
.c-nav--primary__list-item-link--search {
  @apply pb-2;
}
.c-nav--primary__list-item-link-caret {
  @apply hidden self-center ml-1;
  height: 0;
  margin-top: -35px;
  color: #68739a;
}
@media (min-width: 75em) {
  .c-nav--primary__list-item-link-caret {
    @apply inline;
  }
}
.c-nav--primary__list-item-link::after {
  @apply absolute w-0;
  content: "";
  left: 0;
  bottom: 9px;
  height: 2px;
  background: #357184;
  transition: 0.2s width ease-in;
}
@media (min-width: 75em) {
  .c-nav--primary__list-item-link::after {
    background: #5194A9;
  }
}
@media (min-width: 75em) {
  .c-nav--primary__list-item-link:hover, .c-nav--primary__list-item-link:active, .c-nav--primary__list-item-link:focus {
    color: #4E4E4E;
  }
  .c-nav--primary__list-item-link:hover::after, .c-nav--primary__list-item-link:active::after, .c-nav--primary__list-item-link:focus::after {
    @apply w-full;
    background-color: #68739a;
  }
}
.c-nav--primary__list-item-link.utl-is-active::after {
  @apply w-full;
}
@media (min-width: 75em) {
  .c-nav--primary__list-item-link.utl-is-active:hover::after, .c-nav--primary__list-item-link.utl-is-active:active::after, .c-nav--primary__list-item-link.utl-is-active:focus::after {
    @apply w-full;
    background-color: #68739a;
  }
}
.c-nav--primary__dropdown-menu {
  @apply hidden absolute list-none;
}
@media (min-width: 75em) {
  .c-nav--primary__dropdown-menu {
    margin-top: -2px;
    background: #5194A9;
    top: 100%;
    min-width: 250px;
    transition: all 0.3s ease;
    border-top: #2F3444 4px solid;
  }
}
.c-nav--primary__dropdown-menu-item {
  @apply block;
}
.c-nav--primary__dropdown-menu-item-link {
  text-decoration: none;
}
@media (min-width: 75em) {
  .c-nav--primary__dropdown-menu-item-link {
    @apply block;
    color: #fff;
    padding: 10px 18px;
    transition: all 0.15s ease-in-out;
    line-height: 1.3;
  }
}
@media (min-width: 75em) {
  .c-nav--primary__dropdown-menu-item-link:hover, .c-nav--primary__dropdown-menu-item-link:active, .c-nav--primary__dropdown-menu-item-link:focus {
    color: #fff;
    background-color: #2F3444;
  }
}
@media (min-width: 75em) {
  .c-nav--primary__dropdown-menu-item-link.utl-is-active {
    background-color: #3d4150;
    color: #fff;
  }
}

@media (min-width: 75em) {
  .navbar--centre {
    @apply flex-col items-center;
  }
  .navbar--centre .c-nav--primary__wrapper {
    @apply w-full;
  }
  .navbar--centre .c-nav--primary__list {
    @apply justify-center;
  }
}

@keyframes dropDownVisible {
  0% {
    left: -4px;
    opacity: 0;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}
@media (min-width: 75em) {
  .has-dropdown:hover > .c-nav--primary__dropdown-menu, .has-dropdown:active > .c-nav--primary__dropdown-menu, .has-dropdown:focus > .c-nav--primary__dropdown-menu {
    display: block;
    animation: dropDownVisible 0.2s ease-in;
    animation-fill-mode: both;
  }
  .has-dropdown:hover .c-nav--primary__list-item-link, .has-dropdown:active .c-nav--primary__list-item-link, .has-dropdown:focus .c-nav--primary__list-item-link {
    color: #4E4E4E;
  }
  .has-dropdown:hover .c-nav--primary__list-item-link::after, .has-dropdown:active .c-nav--primary__list-item-link::after, .has-dropdown:focus .c-nav--primary__list-item-link::after {
    width: 0;
    background-color: transparent;
  }
  .has-dropdown:focus-within .c-nav--primary__dropdown-menu {
    display: block;
    animation: dropDownVisible 0.2s ease-in;
    animation-fill-mode: both;
  }
}

.c-nav--secondary {
  @apply hidden;
}
@media (min-width: 75em) {
  .c-nav--secondary {
    font-size: 0.9rem;
    @apply inline-block w-full items-center;
  }
}
.c-nav--secondary__wrapper {
  @apply flex flex-grow flex-wrap;
}
.c-nav--secondary__list {
  @apply flex justify-end list-none;
}
.c-nav--secondary__list-item {
  @apply relative inline-flex;
  padding: 0 6px;
}
.c-nav--secondary__list-item-link {
  @apply relative self-center;
  color: #fff;
  padding: 3px 9px;
  text-decoration: none;
}
.c-nav--secondary__list-item-link:hover, .c-nav--secondary__list-item-link:active, .c-nav--secondary__list-item-link:focus {
  color: #fff;
}
.c-nav--secondary__list-item-link > svg {
  width: 14px;
  height: 14px;
  margin-right: 10px;
  align-self: center;
}

.nav--sitemap {
  @apply block my-12;
}
.nav--sitemap__list {
  list-style-type: disc;
}
.nav--sitemap__list--indented {
  padding-left: 2em;
  list-style-type: circle;
}
.a-nav-toggle {
  @apply block self-center;
}
@media (min-width: 75em) {
  .a-nav-toggle {
    @apply hidden;
  }
}
.a-nav-toggle.utl-is-active {
  @apply relative z-50;
}

.c-nav--mobile__wrapper {
  @apply self-center mx-auto;
}
@media (max-width: 74.99em) {
  .c-nav--mobile__wrapper {
    justify-content: flex-end;
    display: flex;
    width: 100%;
  }
}

.c-nav--primary__list-item-plus {
  @apply hidden;
}

@media (max-width: 74.99em) {
  .c-nav--primary {
    font-size: 1.15rem;
    width: 86.5%;
    max-width: 1640px;
    @apply mx-auto;
  }
}
@media (max-width: 74.99em) and (min-width: 62em) {
  .c-nav--primary {
    width: 79.9999999975%;
  }
}
@media (max-width: 74.99em) {
  .c-nav--primary__wrapper {
    @apply hidden fixed z-40 overflow-auto;
    top: 95px;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #5194A9;
    padding-top: 20px;
  }
}
@media (max-width: 74.99em) and (min-width: 62em) {
  .c-nav--primary__wrapper {
    top: 110px;
  }
}
@media (max-width: 74.99em) {
  .c-nav--primary__wrapper.utl-is-active {
    @apply block;
  }
}
@media (max-width: 74.99em) {
  .c-nav--primary__list {
    @apply flex-col p-0;
  }
  .c-nav--primary__list-item {
    @apply flex flex-wrap py-1 px-0;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .c-nav--primary__list-item:last-of-type {
    border-bottom: none;
  }
  .c-nav--primary__list-item-link {
    @apply relative;
    color: #fff;
    border-radius: 40px;
    padding: 5px 20px;
    margin: 5px 0;
  }
  .c-nav--primary__list-item-link-caret {
    display: none;
  }
  .c-nav--primary__list-item-link > .a-icon {
    margin-left: 0;
  }
  .c-nav--primary__list-item-link:hover, .c-nav--primary__list-item-link:active, .c-nav--primary__list-item-link:focus {
    background: transparent;
    color: #fff;
  }
  .c-nav--primary__list-item-link.utl-is-active {
    background: #fff;
    color: #68739a;
  }
  .c-nav--primary__list-item-link.utl-is-active::after {
    background: transparent;
  }
  .c-nav--primary__list-item-link.utl-is-active:hover::after, .c-nav--primary__list-item-link.utl-is-active:active::after, .c-nav--primary__list-item-link.utl-is-active:focus::after {
    background: transparent;
  }
  @keyframes dropDownVisible {
    0% {
      top: -10px;
      opacity: 0;
    }
    100% {
      top: 0;
      opacity: 1;
    }
  }
  .c-nav--primary__list-item.has-dropdown > .c-nav--primary__dropdown-menu {
    display: none;
    position: relative;
    width: 100%;
    margin-top: 0;
    background: transparent;
  }
  .c-nav--primary__list-item.has-dropdown > .c-nav--primary__dropdown-menu.dropdown-active {
    display: block;
    animation: dropDownVisible 0.5s ease-in-out;
    animation-fill-mode: both;
  }
  .c-nav--primary__list-item__plus {
    @apply absolute block;
    right: 0;
    top: 0;
    z-index: 0;
    width: 58px;
    height: 58px;
    margin-top: 5px;
  }
  .c-nav--primary__list-item__plus::before {
    content: "";
    width: 15px;
    height: 3px;
    position: absolute;
    top: 27px;
    left: 24px;
    background-color: #fff;
    z-index: 1;
  }
  .c-nav--primary__list-item__plus[aria-expanded=false]::after {
    content: "";
    width: 3px;
    height: 15px;
    position: absolute;
    top: 21px;
    left: 30px;
    background-color: #fff;
    z-index: 2;
  }
  .c-nav--primary__list-item__plus[aria-expanded=true] + .c-nav--primary__dropdown-menu {
    @apply block;
  }
  .c-nav--primary__list-item__plus.utl-is-expanded + .c-nav--primary__dropdown-menu {
    animation: dropDownVisible 0.5s ease-in-out;
    animation-fill-mode: both;
  }
}
@media (max-width: 74.99em) {
  .c-nav--primary__dropdown-menu {
    @apply pl-4;
  }
  .c-nav--primary__dropdown-menu-item-link {
    @apply inline-block relative;
    color: #fff;
    border-radius: 40px;
    padding: 5px 20px;
    margin: 5px 0;
  }
  .c-nav--primary__dropdown-menu-item-link:hover, .c-nav--primary__dropdown-menu-item-link:active, .c-nav--primary__dropdown-menu-item-link:focus {
    background: transparent;
    color: #fff;
  }
  .c-nav--primary__dropdown-menu-item-link.utl-is-active {
    background: #fff;
    color: #68739a;
  }
}
@media (max-width: 74.99em) {
  .utl-menu-open {
    overflow: hidden;
  }
}

.c-nav--mobile__list-item {
  display: none;
}
@media (max-width: 74.99em) {
  .c-nav--mobile__list-item {
    display: inline-flex !important;
    position: relative;
    align-self: center;
    color: #454444;
    padding: 3px 13px;
    font-weight: 500;
  }
}
@media (max-width: 74.99em) {
  .c-nav--mobile__list-item__phone {
    @apply hidden;
  }
}

@media (max-width: 74.99em) {
  .has-dropdown > .c-nav--primary__list-item-link {
    padding-right: 46px;
  }
}

.c-sidebar {
  @apply my-4;
  padding: 2rem 1rem;
  background-color: transparent;
}
@media (min-width: 75em) {
  .c-sidebar {
    @apply sticky mt-16;
    top: 150px;
  }
}
.c-sidebar__heading {
  @apply block pt-4 pb-2 my-0;
  color: #68739a;
  text-decoration: none;
  font-weight: 700;
}
.c-sidebar__heading--active {
  color: #68739a;
}
.c-sidebar__heading--nested {
  @apply block py-0 my-0;
  margin-bottom: 5px;
}
.c-sidebar__heading:matches(a):hover, .c-sidebar__heading:matches(a):active, .c-sidebar__heading:matches(a):focus {
  color: #357184;
}
.c-sidebar__list {
  @apply ml-4;
}
.c-sidebar__list-item {
  @apply relative;
  line-height: 1.65;
}
.c-sidebar__list-item__link {
  @apply block my-2 px-3;
  color: #5194A9;
  text-decoration: none;
  border-radius: 15px;
  transition: all 0.275s ease-in-out;
}
.c-sidebar__list-item__link:hover, .c-sidebar__list-item__link:active, .c-sidebar__list-item__link:focus {
  background-color: #efefef;
  color: #357184;
}
.c-sidebar__list-item__link.utl-is-active {
  color: #fff;
  background-color: #68739a;
}
.c-sidebar__list-item__link.utl-is-active:hover, .c-sidebar__list-item__link.utl-is-active:active, .c-sidebar__list-item__link.utl-is-active:focus {
  color: #fff;
  background-color: #68739a;
}
.c-sidebar__list-item__descendants {
  @apply hidden;
}
.c-sidebar__list-item__descendants .c-sidebar__list-item {
  @apply ml-4;
}
.c-sidebar__plus {
  @apply absolute;
  width: 30px;
  height: 30px;
  display: block;
  left: -32px;
  top: 0px;
}
.c-sidebar__plus::before {
  content: "";
  width: 15px;
  height: 3px;
  position: absolute;
  top: 13px;
  left: 8px;
  background-color: #b9b9b9;
}
.c-sidebar__plus[aria-expanded=false]::after {
  content: "";
  width: 3px;
  height: 15px;
  position: absolute;
  top: 7px;
  left: 14px;
  background-color: #b9b9b9;
}
.c-sidebar__plus[aria-expanded=true] + .c-sidebar__list-item__link + .c-sidebar__list-item__descendants {
  @apply block;
}
.c-sidebar__plus.utl-is-expanded + .c-sidebar__list-item__link + .c-sidebar__list-item__descendants {
  animation: dropDownVisible 0.5s ease-in-out;
  animation-fill-mode: both;
}

.c-footer--primary {
  @apply py-8;
  background: #0C0D11;
  color: #efefef;
}
@media (min-width: 48em) {
  .c-footer--primary {
    @apply py-20;
  }
}
.c-footer--primary__link {
  @apply flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  color: #efefef;
}
.c-footer--primary__link:hover, .c-footer--primary__link:active, .c-footer--primary__link:focus {
  color: #5194A9;
  text-decoration: none;
}
@media (min-width: 1024px) {
  .c-footer--primary__link {
    align-items: flex-end;
  }
}
.c-footer--primary__link a {
  color: #efefef;
}
.c-footer--primary__link__muted {
  color: #efefef;
}
.c-footer--primary__link--page {
  @apply block;
  margin-bottom: 1rem;
  color: #357184;
  text-decoration: underline;
}
.c-footer--primary__link--page:hover, .c-footer--primary__link--page:active, .c-footer--primary__link--page:focus {
  color: #5194A9;
  text-decoration: none;
}
.c-footer--primary__column {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}
.c-footer--primary__column--first {
  flex-grow: 1;
}
@media (min-width: 48em) and (max-width: 61.99em) {
  .c-footer--primary__column--first {
    flex: 1;
  }
}
@media (min-width: 62em) {
  .c-footer--primary__column {
    margin-left: 330px;
    display: flex;
    flex-direction: column;
    line-height: 2;
    align-items: flex-end;
  }
}
@media (min-width: 48em) {
  .c-footer--primary__nav {
    column-count: 1;
  }
}
.c-footer--primary__title {
  margin-bottom: 0 auto;
}
@media (min-width: 1210px) {
  .c-footer--primary__title {
    margin-top: 2rem;
  }
}
.c-footer--primary__contact {
  margin-left: -10px;
}
@media (max-width: 47.99em) {
  .c-footer--primary__contact {
    @apply pt-4;
  }
}
.c-footer--primary__contact-link {
  @apply flex;
  color: #357184;
  text-decoration: none;
}
.c-footer--primary__contact-link:hover, .c-footer--primary__contact-link:active, .c-footer--primary__contact-link:focus {
  color: #5194A9;
}
.c-footer--primary__contact-link > svg {
  width: 18px;
  height: 18px;
  margin-right: 10px;
  align-self: center;
}

.c-footer--secondary {
  @apply relative;
  background: #15171E;
  color: #b9b9b9;
  @apply py-2;
}
@media (min-width: 48em) {
  .c-footer--secondary {
    @apply py-4;
  }
}
.c-footer--secondary__wrapper {
  @apply flex flex-wrap;
}
.c-footer--secondary__column {
  @apply w-full;
}
@media (min-width: 48em) {
  .c-footer--secondary__column--1 {
    @apply w-3/4;
  }
}
.c-footer--secondary__column--2 {
  @apply mt-1 text-center;
}
@media (min-width: 48em) {
  .c-footer--secondary__column--2 {
    @apply w-1/4;
  }
}
@media (min-width: 90em) {
  .c-footer--secondary__column--2 {
    @apply text-right;
  }
}
.c-footer--secondary__list {
  @apply flex mx-2 list-none p-0 m-0;
}
.c-footer--secondary__list li + li:before {
  content: "|";
  padding: 0 5px;
}
.c-footer--secondary__list-item__link {
  color: #5194A9;
  text-decoration: none;
  text-decoration: none;
}
.c-footer--secondary__list-item__link:hover, .c-footer--secondary__list-item__link:active, .c-footer--secondary__list-item__link:focus {
  color: #357184;
  text-decoration: none;
}
@media (min-width: 62em) {
  .c-footer--secondary__credit {
    @apply text-right;
  }
}

.banner {
  background: #15171E;
  overflow: hidden;
}
.banner.banner--large {
  overflow: visible;
  position: relative;
}
@media (max-width: 35.99em) {
  .banner.banner--large {
    margin-bottom: 380px;
  }
}
@media (max-width: 520px) {
  .banner.banner--large {
    margin-bottom: 350px;
  }
}
@media (max-width: 400px) {
  .banner.banner--large {
    margin-bottom: 500px;
  }
}
@media (max-width: 350px) {
  .banner.banner--large {
    margin-bottom: 600px;
  }
}
@media (max-width: 300px) {
  .banner.banner--large {
    margin-bottom: 800px;
  }
}
@media (min-width: 48em) {
  .banner.banner--large {
    @apply mb-4;
  }
}
@media (min-width: 62em) {
  .banner.banner--large {
    @apply mb-24;
  }
}
.banner__text {
  position: relative;
  z-index: 2;
  max-width: 630px;
  transform: translateY(-50px);
}
@media (max-width: 47.99em) {
  .banner__text {
    transform: none;
    top: inherit;
    margin-top: -270px;
  }
}
@media (max-width: 400px) {
  .banner__text {
    margin-top: -300px;
  }
}
@media (max-width: 350px) {
  .banner__text {
    margin-top: -420px;
  }
}
@media (max-width: 300px) {
  .banner__text {
    margin-top: -350px;
  }
}
.banner__text__heading {
  @apply pl-0;
  font-weight: 700;
  font-size: 20px;
  font-family: "Jost", sans-serif;
  line-height: 1.3;
  background: transparent;
  font-size: 3rem;
  line-height: 1.1;
  @apply mb-2;
}
@media (min-width: 48em) {
  .banner__text__heading {
    font-size: 5rem;
    @apply mb-8;
  }
}
.banner__text__description {
  @apply pt-8 px-8 pt-4 mb-0;
  background: #2F3444;
  font-weight: 700;
}
@media (min-width: 48em) {
  .banner__text__description {
    @apply pt-12 px-16 pt-8 mb-0;
  }
}
.banner__links {
  @apply mt-0 mb-24 mx-0 pt-8 pb-12 px-16 block flex justify-end;
  background: #2F3444;
}
@media (min-width: 48em) {
  .banner__links {
    @apply mb-0;
  }
}
.banner__links > .a-button--primary {
  @apply mt-0;
}
@media (max-width: 47.99em) {
  .banner__image {
    position: relative;
    transform: none;
    top: inherit;
  }
}
@media (max-width: 112.4375em) {
  .banner--slider > .swiper-wrapper {
    position: absolute !important;
  }
}

@media (max-width: 35.99em) {
  .swiper-button-prev, .swiper-container-rtl .swiper-button-next {
    left: 5px !important;
  }

  .swiper-button-next, .swiper-container-rtl .swiper-button-prev {
    right: 5px !important;
  }

  .swiper-button-next:after, .swiper-button-prev:after {
    font-size: 2rem !important;
  }
}
@media (max-width: 23.4375em) {
  .swiper-button-prev, .swiper-container-rtl .swiper-button-next {
    left: 0px !important;
  }

  .swiper-button-next, .swiper-container-rtl .swiper-button-prev {
    right: 0px !important;
  }

  .swiper-button-next:after, .swiper-button-prev:after {
    font-size: 1.75rem !important;
  }
}
@keyframes heroScale {
  0% {
    opacity: 0;
    transform: scale(1);
  }
  100% {
    opacity: 1;
    transform: scale(1.1);
  }
}
.banner--large .swiper-slide .loaded {
  animation: heroScale 2s ease-out;
  animation-fill-mode: forwards;
  position: relative;
  z-index: 1;
}

.banner--slider__image picture {
  overflow: hidden;
  position: relative;
  z-index: 1;
}

@keyframes heroText {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
@keyframes heroLink {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
.banner--large .banner--slider__text__heading,
.banner--large .banner--slider__text__description,
.banner--large .banner__links {
  opacity: 0;
}

.no-js .banner--slider__text__heading,
.no-js .banner--large .banner--slider__text__description,
.no-js .banner--large .banner__links {
  opacity: 1;
}

.banner--large .swiper-slide .banner--slider__text__heading {
  animation: heroText 0.5s ease-out;
  animation-fill-mode: forwards;
  position: relative;
  z-index: 1;
  animation-delay: 0.5s;
}

.banner--large .swiper-slide .banner--slider__text__description {
  animation: heroText 0.5s ease-out;
  animation-fill-mode: forwards;
  position: relative;
  z-index: 1;
  animation-delay: 0.75s;
}

.banner--large .swiper-slide .banner__links {
  animation: heroLink 0.5s ease-out;
  animation-fill-mode: forwards;
  position: relative;
  z-index: 1;
  animation-delay: 1s;
}

.c-prevNext {
  @apply pt-0;
}
.c-prevNext__icon {
  color: #68739a;
  min-width: 25px;
}
.relatedArticles {
  @apply my-24;
}
.relatedArticles__wrapper {
  @apply flex flex-wrap justify-around my-12 mt-4;
}
.relatedArticles__article {
  position: relative;
  flex-basis: 80%;
  max-width: 80%;
}
@media (min-width: 48em) {
  .relatedArticles__article {
    flex-basis: 31.33%;
    max-width: 31.33%;
  }
}
.relatedArticles__article__text {
  justify-content: space-between;
}
.relatedArticles__article__text svg {
  height: 20px;
  min-width: 20px;
  color: #5194A9;
  margin: 1.4em 1.5em 0;
  transition: transform 0.3s ease-in-out;
}
.relatedArticles__article:hover svg, .relatedArticles__article:active svg, .relatedArticles__article:focus svg {
  transform: translate(1.2em);
}

.c-testimonial {
  align-items: center;
  padding: 20px 20px 40px 10px;
  background: #efefef;
  margin: 1em 0;
}
@media (min-width: 36em) {
  .c-testimonial {
    @apply flex items-center;
    padding: 20px 40px 40px;
  }
}
.c-testimonial__wrapper {
  @apply pb-12;
}
@media (min-width: 62em) {
  .c-testimonial__wrapper {
    @apply my-24;
    max-width: 1000px;
    margin: 0 auto;
  }
}
.c-testimonial__image {
  margin: 0 auto;
  height: 80px;
  width: 80px;
}
@media (min-width: 36em) {
  .c-testimonial__image {
    @apply mt-6;
    height: 120px;
    width: 120px;
    min-width: 120px;
  }
}
@media (min-width: 62em) {
  .c-testimonial__image {
    width: 20%;
    height: auto;
  }
}
.c-testimonial__quote {
  margin: 1em;
}
.c-testimonial__quote svg {
  height: 20px;
  width: 20px;
  color: #000;
}
#show-more {
  display: none;
}

.c-breadcrumbs > ul {
  margin-left: 0 !important;
}
.c-socialIcons {
  display: flex;
  align-self: center;
  max-width: 180px;
}
.c-socialIcons > * + * {
  margin-left: 0.5rem;
}
.c-socialIcons__link {
  display: flex;
  align-items: center;
  justify-content: center;
}
.c-socialIcons__link svg {
  width: 18px;
  height: 18px;
}
.c-socialIcons.c-socialIcons--footer {
  @apply pt-4;
  margin-left: -10px;
}
.c-socialIcons.c-socialIcons--footer .c-socialIcons__link {
  color: #5194A9;
}
.c-socialIcons.c-socialIcons--footer .c-socialIcons__link:hover, .c-socialIcons.c-socialIcons--footer .c-socialIcons__link:active, .c-socialIcons.c-socialIcons--footer .c-socialIcons__link:focus {
  color: #357184;
}
.c-socialIcons.c-socialIcons--navigation .c-socialIcons__link {
  color: #4E4E4E;
}
@media (min-width: 75em) {
  .c-socialIcons.c-socialIcons--navigation .c-socialIcons__link {
    color: #fff;
  }
}
.c-socialIcons.c-socialIcons--navigation .c-socialIcons__link:hover, .c-socialIcons.c-socialIcons--navigation .c-socialIcons__link:active, .c-socialIcons.c-socialIcons--navigation .c-socialIcons__link:focus {
  color: #357184;
}

.subscribe {
  @apply py-8;
  background: #efefef;
}
@media (min-width: 90em) {
  .subscribe {
    @apply py-10;
  }
}
.subscribe__privacy {
  @apply my-12;
  text-align: center;
  font-size: 1rem;
  color: #4E4E4E;
}

.mailchimp-form {
  @apply flex my-8;
  margin: 0 auto;
  max-width: 1000px;
}
@media (max-width: 61.99em) {
  .mailchimp-form {
    flex-direction: column;
    width: 80%;
  }
}
.mailchimp-form__input {
  @apply flex;
  width: 100%;
}
@media (max-width: 61.99em) {
  .mailchimp-form__input {
    margin-bottom: 1em;
  }
}
.mailchimp-form__input > input {
  margin-bottom: 0;
  padding: 0 1em;
  border: 1px solid #b9b9b9;
}
@media (min-width: 62em) {
  .mailchimp-form__input > input {
    width: 93%;
  }
}
.mailchimp-form__input > input::placeholder {
  font-size: 1rem;
  color: #4E4E4E;
}
.mailchimp-form__submit {
  border: none;
  margin: 0 !important;
  justify-content: center;
  font-size: 1rem;
  color: #4E4E4E;
  font-weight: 700;
}
@media (max-width: 61.99em) {
  .mailchimp-form__submit {
    padding: 20px;
  }
}

.practice__wrapper {
  @apply w-full relative flex flex-col;
  text-decoration: none;
  background-color: #0C0D11;
}
@media (max-width: 47.99em) {
  .practice__wrapper {
    margin-top: -30px;
    margin-left: -30px;
    margin-right: -30px;
  }
}
@media (min-width: 1024px) {
  .practice__wrapper {
    @apply w-full flex flex-row;
    width: 1700px;
    margin-left: -400px;
    justify-content: space-around;
  }
}
@media (min-width: 1300px) {
  .practice__wrapper {
    width: 1900px;
    margin-left: -400px;
  }
}
@media (min-width: 1600px) {
  .practice__wrapper {
    width: 2200px;
    margin-left: -430px;
  }
}
.practice__list {
  @apply w-full relative;
  padding: 2rem;
}
@media (min-width: 1024px) {
  .practice__list {
    width: 30%;
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
}
.practice__list h3, .practice__list .h3 {
  padding-left: 2rem;
}
.practice__text {
  @apply w-full relative;
  padding: 2rem;
}
@media (min-width: 1024px) {
  .practice__text {
    @apply my-12;
    width: 25%;
    margin-left: -700px;
    margin-top: 6rem;
    margin-bottom: 4rem;
  }
}
.practice__button {
  padding: 0.5rem;
  color: #fff;
  text-align: right;
  border-bottom: 8px solid #5194A9;
  width: 40%;
}
@media (min-width: 48em) {
  .practice__button {
    @apply w-1/2;
  }
}
.practice__button--link {
  color: #efefef;
  letter-spacing: 6px;
}

.cases {
  @apply w-full;
  padding: 1rem;
  position: relative;
  align-items: right;
}
.cases__wrapper {
  @apply flex;
}
.cases__text {
  padding-bottom: 2rem;
}
.cases__button {
  padding: 0.5rem;
  color: #fff;
  text-align: right;
  border-bottom: 8px solid #5194A9;
  width: 40%;
}
@media (min-width: 48em) {
  .cases__button {
    width: 50%;
  }
}
.cases__button--link {
  color: #efefef;
  letter-spacing: 6px;
}
@media (min-width: 62em) {
  .cases {
    @apply w-1/2;
  }
}

.c-image-matrix {
  @apply pt-8 pb-8 px-24;
  position: absolute;
}
.c-image-matrix__container {
  @apply -mx-24;
}
.c-image-matrix__heading {
  position: relative;
}
.c-image-matrix__content {
  position: relative;
  font-size: 0.9em;
  z-index: 1;
  color: #fff;
}
.c-image-matrix__image {
  position: relative;
}
.c-image-matrix.u-l-1.u-i-1.c-image-matrix {
  @apply w-full relative;
}
.c-image-matrix.u-l-1.u-i-1.c-image-matrix::before {
  @apply absolute -ml-8;
  content: "";
  margin: 2rem;
  background-color: #2F3444;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
@media (min-width: 62em) {
  .c-image-matrix.u-l-1.u-i-1.c-image-matrix {
    @apply w-3/5;
  }
}
.c-image-matrix.u-l-2.u-i-1.c-image-matrix {
  @apply w-full relative;
}
.c-image-matrix.u-l-2.u-i-1.c-image-matrix::before {
  @apply absolute -ml-8;
  content: "";
  margin: 2rem;
  background-color: #2F3444;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.c-image-matrix.u-l-2.u-i-1.c-image-matrix__link {
  color: #5194A9 !important;
}
@media (min-width: 62em) {
  .c-image-matrix.u-l-2.u-i-1.c-image-matrix {
    @apply w-3/5;
  }
}
.c-image-matrix.u-l-2.u-i-2.c-image-matrix {
  @apply w-full relative;
}
.c-image-matrix.u-l-2.u-i-2.c-image-matrix::before {
  @apply absolute -mr-8;
  content: "";
  margin: 2rem;
  background-color: #5194A9;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
@media (min-width: 62em) {
  .c-image-matrix.u-l-2.u-i-2.c-image-matrix {
    @apply w-2/5;
    margin-top: -12rem;
  }
}
.c-image-matrix.u-l-3.u-i-1.c-image-matrix {
  @apply w-full relative;
}
.c-image-matrix.u-l-3.u-i-1.c-image-matrix::before {
  @apply absolute -ml-8;
  content: "";
  margin: 2rem;
  background-color: #2F3444;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.c-image-matrix.u-l-3.u-i-1.c-image-matrix__link {
  color: #5194A9 !important;
}
@media (min-width: 62em) {
  .c-image-matrix.u-l-3.u-i-1.c-image-matrix {
    @apply w-3/5;
  }
}
.c-image-matrix.u-l-3.u-i-2.c-image-matrix {
  @apply w-full relative;
}
.c-image-matrix.u-l-3.u-i-2.c-image-matrix::before {
  @apply absolute -mr-8;
  content: "";
  margin: 2rem;
  background-color: #5194A9;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
@media (min-width: 62em) {
  .c-image-matrix.u-l-3.u-i-2.c-image-matrix {
    @apply w-2/5;
    margin-top: -12rem;
  }
}
.c-image-matrix.u-l-3.u-i-3.c-image-matrix {
  @apply w-full relative;
}
.c-image-matrix.u-l-3.u-i-3.c-image-matrix::before {
  @apply absolute -ml-8;
  content: "";
  margin: 2rem;
  background-color: #5194A9;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
@media (min-width: 62em) {
  .c-image-matrix.u-l-3.u-i-3.c-image-matrix {
    @apply w-2/5;
  }
}
.c-image-matrix.u-l-4.u-i-1.c-image-matrix {
  @apply w-full relative;
}
.c-image-matrix.u-l-4.u-i-1.c-image-matrix::before {
  @apply absolute -ml-8;
  content: "";
  margin: 2rem;
  background-color: #2F3444;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.c-image-matrix.u-l-4.u-i-1.c-image-matrix__link {
  color: #5194A9;
}
@media (min-width: 62em) {
  .c-image-matrix.u-l-4.u-i-1.c-image-matrix {
    @apply w-3/5;
  }
}
.c-image-matrix.u-l-4.u-i-2.c-image-matrix {
  @apply w-full relative;
}
.c-image-matrix.u-l-4.u-i-2.c-image-matrix::before {
  @apply absolute -mr-8;
  content: "";
  margin: 2rem;
  background-color: #5194A9;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.c-image-matrix.u-l-4.u-i-2.c-image-matrix a {
  color: #2F3444 !important;
}
.c-image-matrix.u-l-4.u-i-2.c-image-matrix a:hover {
  color: #68739a;
}
@media (min-width: 62em) {
  .c-image-matrix.u-l-4.u-i-2.c-image-matrix {
    @apply w-2/5;
    margin-top: -12rem;
  }
}
.c-image-matrix.u-l-4.u-i-3.c-image-matrix {
  @apply w-full relative;
}
.c-image-matrix.u-l-4.u-i-3.c-image-matrix::before {
  @apply absolute -ml-8;
  content: "";
  margin: 2rem;
  background-color: #2F3444;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
@media (min-width: 62em) {
  .c-image-matrix.u-l-4.u-i-3.c-image-matrix::before {
    background-color: #5194A9;
  }
}
.c-image-matrix.u-l-4.u-i-3.c-image-matrix a {
  color: #2F3444 !important;
}
.c-image-matrix.u-l-4.u-i-3.c-image-matrix a a:hover {
  color: #68739a;
}
.c-image-matrix.u-l-4.u-i-3.c-image-matrix a:hover {
  color: #68739a;
}
@media (min-width: 62em) {
  .c-image-matrix.u-l-4.u-i-3.c-image-matrix {
    @apply w-2/5;
  }
}
.c-image-matrix.u-l-4.u-i-4.c-image-matrix {
  @apply w-full relative;
}
.c-image-matrix.u-l-4.u-i-4.c-image-matrix::before {
  @apply absolute -mr-8;
  content: "";
  margin: 2rem;
  background-color: #5194A9;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
@media (min-width: 62em) {
  .c-image-matrix.u-l-4.u-i-4.c-image-matrix::before {
    background-color: #2F3444;
  }
}
.c-image-matrix.u-l-4.u-i-4.c-image-matrix__link {
  color: #2F3444;
}
@media (min-width: 62em) {
  .c-image-matrix.u-l-4.u-i-4.c-image-matrix {
    @apply w-3/5;
    margin-bottom: 12rem;
  }
}

@keyframes in1 {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
@keyframes in2 {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
@keyframes in3 {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
@keyframes in4 {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
.js-animate--start > .c-image-matrix.u-i-1 {
  animation: in1 1s ease;
  animation-fill-mode: both;
  animation-delay: 0.5s;
}
.js-animate--start > .c-image-matrix.u-i-2 {
  animation: in2 1s ease;
  animation-fill-mode: both;
  animation-delay: 0.25s;
}
.js-animate--start > .c-image-matrix.u-i-3 {
  animation: in3 1s ease;
  animation-fill-mode: both;
  animation-delay: 0.75s;
}
.js-animate--start > .c-image-matrix.u-i-4 {
  animation: in4 1s ease;
  animation-fill-mode: both;
  animation-delay: 1s;
}

.pull-quote {
  @apply px-24 flex-wrap;
  position: absolute;
}
.pull-quote__container {
  @apply -mx-24 relative;
}
.pull-quote__wrapper {
  @apply flex flex-col;
  border-top: solid 20px RGBA(53, 113, 132, 1);
  border-bottom: solid 20px RGBA(53, 113, 132, 1);
}
@media (min-width: 90em) {
  .pull-quote__wrapper {
    @apply flex flex-row w-1/2;
    max-width: 500px;
  }
}
.pull-quote__text {
  @apply flex flex-col;
  position: relative;
  color: #5194A9;
  font-family: europa, sans-serif;
  font-weight: 300;
  font-size: 1.622em;
  line-height: 1.8;
}
.pull-quote__content--text {
  @apply flex flex-col w-full;
}
@media (min-width: 62em) {
  .pull-quote__content--text {
    @apply w-1/2;
  }
}

.relatedArticle__wrapper {
  @apply w-full flex flex-col;
  padding: 1.5rem;
  postion: relative;
  max-width: 700px;
}
@media (min-width: 62em) {
  .relatedArticle__wrapper {
    @apply w-1/2;
  }
}
.relatedArticle__button {
  @apply w-full;
  padding: 1.5rem;
  text-align: right;
  border-bottom: 5px solid #5194A9;
  width: 50%;
}
@media (min-width: 48em) {
  .relatedArticle__button {
    width: 50%;
  }
}

@media (min-width: 62em) {
  .b-buttons:nth-child(n+2) {
    animation-delay: 0.3s;
  }
}
@media (min-width: 62em) {
  .b-buttons:nth-child(n+3) {
    animation-delay: 0.45s;
  }
}
.b-buttons__wrapper {
  @apply flex items-center flex-wrap;
}
.b-buttons__wrapper.utl-align--center {
  @apply justify-center;
}
@media (min-width: 62em) {
  .b-buttons__heading {
    @apply px-20;
  }
}
.b-entries {
  @apply text-center;
}
.b-entries__image-wrapper {
  transition: all 0.275s ease-in-out;
}
@media (min-width: 62em) {
  .b-imageText {
    @apply flex;
  }
}
@media (min-width: 62em) {
  .b-imageText__image, .b-imageText__text {
    @apply w-1/2;
  }
}
.b-imageText__image img {
  @apply w-full h-full;
  object-fit: cover;
  object-position: center;
}
.b-imageText__text {
  @apply p-4;
}
@media (min-width: 62em) {
  .b-imageText__text {
    @apply flex flex-col justify-center p-16;
  }
}
.b-imageText__text > *:first-child {
  margin-top: 0;
}
@media (min-width: 62em) {
  .b-imageText.utl-position--right {
    @apply flex-row-reverse;
  }
}

.b-images--default {
  display: flex;
  flex-wrap: wrap;
}
.b-images__image--default {
  flex: 1 1 25%;
}

.js .b-shutters__shutter__content {
  overflow: hidden;
  height: 0;
}

.b-shutters__shutter__title {
  @apply relative flex w-full py-4 px-8;
  justify-content: space-between;
  border: 2px solid #efefef;
  border-radius: 5px;
  color: #68739a;
  background: #efefef;
  text-align: left;
  outline: 2px solid transparent;
  cursor: pointer;
  font-size: 1.2em;
  margin-bottom: 10px;
  font-weight: 700;
  transition: all 0.25s ease-in-out;
  align-items: center;
}
.b-shutters__shutter__title > svg {
  @apply block;
  height: 36px;
  width: 36px;
  fill: #68739a;
  transition: all 0.25s ease-in-out;
  align-self: center;
  z-index: 2;
}
.b-shutters__shutter__title:hover, .b-shutters__shutter__title:active, .b-shutters__shutter__title:focus {
  color: #fff;
  background: #5194A9;
}
.b-shutters__shutter__title:hover > svg, .b-shutters__shutter__title:active > svg, .b-shutters__shutter__title:focus > svg {
  fill: #fff;
}
.b-shutters__shutter__title.utl-is-active {
  color: #fff;
  background: #68739a;
}
.b-shutters__shutter__title.utl-is-active svg {
  fill: #fff;
  transform: rotate(90deg);
}
.b-shutters__shutter__title.utl-is-active + .b-shutters__shutter__content {
  height: 100%;
}
.b-shutters__shutter__content {
  margin-bottom: 1px;
}
.b-shutters__shutter__content__inner {
  @apply py-4 px-5 mb-8;
}

.b-text.utl-align--center {
  text-align: center;
}

@media (min-width: 62em) {
  .b-buttons:nth-child(n+2) {
    animation-delay: 0.3s;
  }
}
@media (min-width: 62em) {
  .b-buttons:nth-child(n+3) {
    animation-delay: 0.45s;
  }
}
.b-buttons__wrapper {
  @apply flex items-center flex-wrap;
}
.b-buttons__wrapper.utl-align--center {
  @apply justify-center;
}
@media (min-width: 62em) {
  .b-buttons__heading {
    @apply px-20;
  }
}
.b-entries {
  @apply text-center;
}
.b-entries__image-wrapper {
  transition: all 0.275s ease-in-out;
}
@media (min-width: 62em) {
  .b-imageText {
    @apply flex;
  }
}
@media (min-width: 62em) {
  .b-imageText__image, .b-imageText__text {
    @apply w-1/2;
  }
}
.b-imageText__image img {
  @apply w-full h-full;
  object-fit: cover;
  object-position: center;
}
.b-imageText__text {
  @apply p-4;
}
@media (min-width: 62em) {
  .b-imageText__text {
    @apply flex flex-col justify-center p-16;
  }
}
.b-imageText__text > *:first-child {
  margin-top: 0;
}
@media (min-width: 62em) {
  .b-imageText.utl-position--right {
    @apply flex-row-reverse;
  }
}

.b-images--default {
  display: flex;
  flex-wrap: wrap;
}
.b-images__image--default {
  flex: 1 1 25%;
}

.js .b-shutters__shutter__content {
  overflow: hidden;
  height: 0;
}

.b-shutters__shutter__title {
  @apply relative flex w-full py-4 px-8;
  justify-content: space-between;
  border: 2px solid #efefef;
  border-radius: 5px;
  color: #68739a;
  background: #efefef;
  text-align: left;
  outline: 2px solid transparent;
  cursor: pointer;
  font-size: 1.2em;
  margin-bottom: 10px;
  font-weight: 700;
  transition: all 0.25s ease-in-out;
  align-items: center;
}
.b-shutters__shutter__title > svg {
  @apply block;
  height: 36px;
  width: 36px;
  fill: #68739a;
  transition: all 0.25s ease-in-out;
  align-self: center;
  z-index: 2;
}
.b-shutters__shutter__title:hover, .b-shutters__shutter__title:active, .b-shutters__shutter__title:focus {
  color: #fff;
  background: #5194A9;
}
.b-shutters__shutter__title:hover > svg, .b-shutters__shutter__title:active > svg, .b-shutters__shutter__title:focus > svg {
  fill: #fff;
}
.b-shutters__shutter__title.utl-is-active {
  color: #fff;
  background: #68739a;
}
.b-shutters__shutter__title.utl-is-active svg {
  fill: #fff;
  transform: rotate(90deg);
}
.b-shutters__shutter__title.utl-is-active + .b-shutters__shutter__content {
  height: 100%;
}
.b-shutters__shutter__content {
  margin-bottom: 1px;
}
.b-shutters__shutter__content__inner {
  @apply py-4 px-5 mb-8;
}

.b-text.utl-align--center {
  text-align: center;
}

.b-buttons {
  text-decoration: none;
}
.b-entries {
  @apply mt-8 mb-2 w-full;
}
.b-entries__wrapper--outer {
  @apply text-center;
}
.b-imageText {
  @apply mt-48 mb-20;
}
.b-imageText--standard > .b-imageText__image {
  @apply mt-12 px-12;
  max-height: 300px;
  overflow: hidden;
  background-color: #5194A9;
}
@media (min-width: 48em) {
  .b-imageText--standard > .b-imageText__image {
    @apply mt-80 px-12;
  }
}
@media (min-width: 62em) {
  .b-imageText--standard > .b-imageText__image {
    @apply mt-96 px-12;
  }
}
.b-imageText--standard > .b-imageText__image img {
  object-fit: cover;
}
@media (min-width: 62em) {
  .b-imageText--standard > .b-imageText__image {
    @apply mt-32;
    max-height: 600px;
    overflow: visible;
  }
  .b-imageText--standard > .b-imageText__image img {
    margin-top: -70px;
    overflow: visible;
  }
}
@media (min-width: 90em) and (max-width: 112.49em) {
  .b-imageText__image {
    @apply flex justify-center;
  }
}
@media (min-width: 90em) and (max-width: 112.49em) {
  .b-imageText__image > picture {
    @apply flex justify-center;
  }
}
.b-imageText__image > picture > img {
  @apply w-full;
}
@media (min-width: 90em) and (max-width: 112.49em) {
  .b-imageText__image > picture > img {
    max-width: initial;
  }
}
.b-imageText__text {
  @apply py-8 pl-0;
}
.b-imageText__text > *:first-child {
  margin-top: 0;
}
.b-imageText__text > *:last-child {
  margin-bottom: 0;
}
.b-imageText__text.utl-has-background-color {
  @apply pl-8;
}
@media (min-width: 90em) {
  .b-imageText__text.utl-has-background-color {
    @apply pl-12;
  }
}
.b-imageText.utl-position--left > .b-imageText__text {
  @apply pl-8;
}
@media (min-width: 90em) {
  .b-imageText.utl-position--left > .b-imageText__text {
    @apply pl-12;
  }
}
@media (min-width: 62em) {
  .b-imageText.utl-position--right {
    @apply flex-row-reverse;
  }
}
.b-imageText.utl-position--right > .b-imageText__text {
  @apply pr-8;
}
@media (min-width: 90em) {
  .b-imageText.utl-position--right > .b-imageText__text {
    @apply pr-12;
  }
}

@keyframes in5 {
  0% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes in6 {
  0% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0px);
  }
}
.js-animate--start > .b-imageText__image {
  animation: in5 1s ease;
  animation-fill-mode: both;
}
.js-animate--start > .b-imageText__image > picture > img {
  animation: in6 1s ease;
  animation-fill-mode: both;
}

.b-images {
  @apply mt-8 mb-2;
}
.b-shutters {
  margin-bottom: 2rem;
}
@media (min-width: 75em) {
  .b-text.utl-align--center {
    @apply mx-auto;
    max-width: 60%;
  }
}

.two-columns {
  @apply my-8;
  column-fill: balance;
}
.two-columns > * {
  margin-top: 0 !important;
  margin-bottom: 2rem !important;
}
.two-columns > hr {
  opacity: 0;
  height: 0;
  margin: 0;
  padding: 0;
  break-after: always;
}

form button,
.a-button {
  display: inline-flex;
  align-items: center;
  font-weight: 700;
  margin: 5px 10px;
  padding: 20px 32px;
  letter-spacing: 0.25px;
}
.a-button__arrow {
  @apply inline;
  @apply ml-2 mr-12;
  transition: margin 0.275s ease-in-out;
}
form button,
.a-button--primary {
  color: #fff;
  background: #5194A9;
  transition: all 0.275s ease-in-out;
}
form button:hover, form button:active, form button:focus,
.a-button--primary:hover,
.a-button--primary:active,
.a-button--primary:focus {
  color: #fff;
  text-shadow: 0 2px 20px rgba(230, 85, 13, 0.2);
  background: #357184;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
.a-button--secondary {
  color: #fff;
  background: #68739a;
}
.a-button--secondary:hover,
.a-button--secondary:active,
.a-button--secondary:focus {
  color: #fff;
  background: #5194A9;
}
.a-button--default {
  color: #000;
  background: #d4d4d4;
}
.a-button--default:hover,
.a-button--default:active,
.a-button--default:focus {
  color: #000;
  background: #efefef;
}
.a-button--outline {
  color: #5194A9;
  border: 1px solid #5194A9;
}
.a-button--outline:hover,
.a-button--outline:active,
.a-button--outline:focus {
  color: #fff;
  background: #5194A9;
}
.a-button--arrow {
  @apply relative overflow-hidden pl-0;
  text-indent: -999px;
}
.a-button--arrow:hover,
.a-button--arrow:active,
.a-button--arrow:focus {
  @apply pl-2;
}
.a-button--arrow::after {
  @apply absolute z-10;
  content: "";
  background-image: url("/assets/images/icon--arrow--right.svg");
  color: #5194A9;
  width: 23px;
  height: 10px;
  right: 56px;
  top: 18px;
  background-size: contain;
}
.a-button--more {
  margin: 0;
}
.a-button--search {
  margin: 0 0 0 -1px;
  transform: translate(0);
  border-radius: 0 5px 5px 0;
  @apply flex;
  align-items: center;
}
.a-button--search svg {
  height: 20px;
  width: 20px;
  margin-right: 0.5em;
}
form button:first-of-type,
.a-button:first-of-type {
  margin-left: 0;
}
form button:last-of-type,
.a-button:last-of-type {
  margin-right: 0;
}

.utl-button-arrows .a-button {
  @apply relative pr-16;
}
.utl-button-arrows .a-button:hover, .utl-button-arrows .a-button:active, .utl-button-arrows .a-button:focus {
  @apply pr-20;
}
.utl-button-arrows .a-button::after {
  @apply absolute z-10;
  content: "";
  background-image: url("/assets/images/icon--arrow--right.svg");
  color: #fff;
  width: 23px;
  height: 10px;
  right: 30px;
  top: 18px;
  background-size: contain;
}

.a-form--search {
  @apply flex;
}
.a-form--search > input {
  margin: 0;
}
.a-form--search .a-form__label {
  width: 100%;
  padding: 0;
}
.a-form--search .a-form__label > input {
  margin: 0;
  border-radius: 5px 0 0 5px;
}

form {
  max-width: 800px;
}

label {
  display: inline-block;
  padding-bottom: 0.5rem;
}

input, textarea {
  display: block;
  width: 100%;
  border: 1px solid #888;
  font-family: europa, sans-serif;
  color: #fff;
  background: #fff;
  transition: 0.3s ease-in-out border-color;
  border-radius: 2px;
  font-size: 1.3rem;
  padding: 15px 20px;
  margin-bottom: 1rem;
}
input:focus, textarea:focus {
  outline: none;
  border-color: #5194A9;
}

@media (min-width: 48em) {
  .input > div {
    display: flex;
  }
}

select {
  height: 64px;
  border: 1px solid #888;
  margin-bottom: 1rem;
}
@media (max-width: 47.99em) {
  select {
    width: 100%;
  }
}
@media (min-width: 62em) {
  select {
    height: 67px;
  }
}
@media (min-width: 90em) {
  select {
    height: 72px;
  }
}

.submit {
  margin-left: -10px;
}

.alert {
  @apply p-4 mb-8;
}

.alert--success {
  background-color: #88C695;
}

.alert--success {
  background-color: #88C695;
}

.alert--danger {
  color: #e01d00;
  background-color: #fcefed;
}

.fieldset--spaced {
  @apply my-16;
}

@media (min-width: 36em) {
  .fieldset--grid,
.field {
    display: grid;
    grid-template-columns: 25% 1fr;
    grid-column-gap: 16px;
  }
}

legend {
  @apply my-4;
  font-size: 1.175em;
  text-transform: uppercase;
  color: #7f7f7f;
}

label,
.heading > label {
  align-self: center;
}

@media (min-width: 36em) {
  label,
.radio__legend,
.field > .heading {
    grid-column: 1/2;
  }
}

@media (min-width: 36em) {
  input,
button,
.align-input,
#gRecaptchaContainer,
.field > .input {
    grid-column: 2/3;
  }
}

#gRecaptchaContainer {
  @apply mb-8;
}

.radio__legend {
  @apply mb-0;
}

.radio__label {
  @apply flex mb-0;
}

.radio__input {
  @apply mb-0;
  width: 30px;
  height: 30px;
}

.input--submit {
  border: none;
  width: initial;
}

.utl-required {
  color: #7f7f7f;
}

@media (min-width: 36em) {
  .submit {
    margin-left: 25%;
  }
}

.card-data {
  @apply mt-6;
}

.a-icon__wrapper > svg {
  display: inline;
}
.a-icon__wrapper--4 > svg {
  width: 4px;
  height: 4px;
}
.a-icon__wrapper--5 > svg {
  width: 5px;
  height: 5px;
}
.a-icon__wrapper--6 > svg {
  width: 6px;
  height: 6px;
}
.a-icon__wrapper--7 > svg {
  width: 7px;
  height: 7px;
}
.a-icon__wrapper--8 > svg {
  width: 8px;
  height: 8px;
}
.a-icon__wrapper--9 > svg {
  width: 9px;
  height: 9px;
}
.a-icon__wrapper--10 > svg {
  width: 10px;
  height: 10px;
}
.a-icon__wrapper--11 > svg {
  width: 11px;
  height: 11px;
}
.a-icon__wrapper--12 > svg {
  width: 12px;
  height: 12px;
}
.a-icon__wrapper--13 > svg {
  width: 13px;
  height: 13px;
}
.a-icon__wrapper--14 > svg {
  width: 14px;
  height: 14px;
}
.a-icon__wrapper--15 > svg {
  width: 15px;
  height: 15px;
}
.a-icon__wrapper--16 > svg {
  width: 16px;
  height: 16px;
}
.a-icon__wrapper--17 > svg {
  width: 17px;
  height: 17px;
}
.a-icon__wrapper--18 > svg {
  width: 18px;
  height: 18px;
}
.a-icon__wrapper--19 > svg {
  width: 19px;
  height: 19px;
}
.a-icon__wrapper--20 > svg {
  width: 20px;
  height: 20px;
}
.a-icon__wrapper--21 > svg {
  width: 21px;
  height: 21px;
}
.a-icon__wrapper--22 > svg {
  width: 22px;
  height: 22px;
}
.a-icon__wrapper--23 > svg {
  width: 23px;
  height: 23px;
}
.a-icon__wrapper--24 > svg {
  width: 24px;
  height: 24px;
}
.a-icon__wrapper--25 > svg {
  width: 25px;
  height: 25px;
}
.a-icon__wrapper--26 > svg {
  width: 26px;
  height: 26px;
}
.a-icon__wrapper--27 > svg {
  width: 27px;
  height: 27px;
}
.a-icon__wrapper--28 > svg {
  width: 28px;
  height: 28px;
}
.a-icon__wrapper--29 > svg {
  width: 29px;
  height: 29px;
}
.a-icon__wrapper--30 > svg {
  width: 30px;
  height: 30px;
}
.a-icon__wrapper--31 > svg {
  width: 31px;
  height: 31px;
}
.a-icon__wrapper--32 > svg {
  width: 32px;
  height: 32px;
}
.a-icon__wrapper--33 > svg {
  width: 33px;
  height: 33px;
}
.a-icon__wrapper--34 > svg {
  width: 34px;
  height: 34px;
}
.a-icon__wrapper--35 > svg {
  width: 35px;
  height: 35px;
}
.a-icon__wrapper--36 > svg {
  width: 36px;
  height: 36px;
}
.a-icon__wrapper--37 > svg {
  width: 37px;
  height: 37px;
}
.a-icon__wrapper--38 > svg {
  width: 38px;
  height: 38px;
}
.a-icon__wrapper--39 > svg {
  width: 39px;
  height: 39px;
}
.a-icon__wrapper--40 > svg {
  width: 40px;
  height: 40px;
}
.a-icon__wrapper--41 > svg {
  width: 41px;
  height: 41px;
}
.a-icon__wrapper--42 > svg {
  width: 42px;
  height: 42px;
}
.a-icon__wrapper--43 > svg {
  width: 43px;
  height: 43px;
}
.a-icon__wrapper--44 > svg {
  width: 44px;
  height: 44px;
}
.a-icon__wrapper--45 > svg {
  width: 45px;
  height: 45px;
}
.a-icon__wrapper--46 > svg {
  width: 46px;
  height: 46px;
}
.a-icon__wrapper--47 > svg {
  width: 47px;
  height: 47px;
}
.a-icon__wrapper--48 > svg {
  width: 48px;
  height: 48px;
}
.a-icon__wrapper--49 > svg {
  width: 49px;
  height: 49px;
}
.a-icon__wrapper--50 > svg {
  width: 50px;
  height: 50px;
}
.a-icon__wrapper--51 > svg {
  width: 51px;
  height: 51px;
}
.a-icon__wrapper--52 > svg {
  width: 52px;
  height: 52px;
}
.a-icon__wrapper--53 > svg {
  width: 53px;
  height: 53px;
}
.a-icon__wrapper--54 > svg {
  width: 54px;
  height: 54px;
}
.a-icon__wrapper--55 > svg {
  width: 55px;
  height: 55px;
}
.a-icon__wrapper--56 > svg {
  width: 56px;
  height: 56px;
}
.a-icon__wrapper--57 > svg {
  width: 57px;
  height: 57px;
}
.a-icon__wrapper--58 > svg {
  width: 58px;
  height: 58px;
}
.a-icon__wrapper--59 > svg {
  width: 59px;
  height: 59px;
}
.a-icon__wrapper--60 > svg {
  width: 60px;
  height: 60px;
}
.a-icon__wrapper--61 > svg {
  width: 61px;
  height: 61px;
}
.a-icon__wrapper--62 > svg {
  width: 62px;
  height: 62px;
}
.a-icon__wrapper--63 > svg {
  width: 63px;
  height: 63px;
}
.a-icon__wrapper--64 > svg {
  width: 64px;
  height: 64px;
}
.a-icon__wrapper--65 > svg {
  width: 65px;
  height: 65px;
}
.a-icon__wrapper--66 > svg {
  width: 66px;
  height: 66px;
}
.a-icon__wrapper--67 > svg {
  width: 67px;
  height: 67px;
}
.a-icon__wrapper--68 > svg {
  width: 68px;
  height: 68px;
}
.a-icon__wrapper--69 > svg {
  width: 69px;
  height: 69px;
}
.a-icon__wrapper--70 > svg {
  width: 70px;
  height: 70px;
}
.a-icon__wrapper--71 > svg {
  width: 71px;
  height: 71px;
}
.a-icon__wrapper--72 > svg {
  width: 72px;
  height: 72px;
}
.a-icon__wrapper--73 > svg {
  width: 73px;
  height: 73px;
}
.a-icon__wrapper--74 > svg {
  width: 74px;
  height: 74px;
}
.a-icon__wrapper--75 > svg {
  width: 75px;
  height: 75px;
}
.a-icon__wrapper--76 > svg {
  width: 76px;
  height: 76px;
}
.a-icon__wrapper--77 > svg {
  width: 77px;
  height: 77px;
}
.a-icon__wrapper--78 > svg {
  width: 78px;
  height: 78px;
}
.a-icon__wrapper--79 > svg {
  width: 79px;
  height: 79px;
}
.a-icon__wrapper--80 > svg {
  width: 80px;
  height: 80px;
}
.a-icon__wrapper--81 > svg {
  width: 81px;
  height: 81px;
}
.a-icon__wrapper--82 > svg {
  width: 82px;
  height: 82px;
}
.a-icon__wrapper--83 > svg {
  width: 83px;
  height: 83px;
}
.a-icon__wrapper--84 > svg {
  width: 84px;
  height: 84px;
}
.a-icon__wrapper--85 > svg {
  width: 85px;
  height: 85px;
}
.a-icon__wrapper--86 > svg {
  width: 86px;
  height: 86px;
}
.a-icon__wrapper--87 > svg {
  width: 87px;
  height: 87px;
}
.a-icon__wrapper--88 > svg {
  width: 88px;
  height: 88px;
}
.a-icon__wrapper--89 > svg {
  width: 89px;
  height: 89px;
}
.a-icon__wrapper--90 > svg {
  width: 90px;
  height: 90px;
}
.a-icon__wrapper--91 > svg {
  width: 91px;
  height: 91px;
}
.a-icon__wrapper--92 > svg {
  width: 92px;
  height: 92px;
}
.a-icon__wrapper--93 > svg {
  width: 93px;
  height: 93px;
}
.a-icon__wrapper--94 > svg {
  width: 94px;
  height: 94px;
}
.a-icon__wrapper--95 > svg {
  width: 95px;
  height: 95px;
}
.a-icon__wrapper--96 > svg {
  width: 96px;
  height: 96px;
}
.a-icon__wrapper--97 > svg {
  width: 97px;
  height: 97px;
}
.a-icon__wrapper--98 > svg {
  width: 98px;
  height: 98px;
}
.a-icon__wrapper--99 > svg {
  width: 99px;
  height: 99px;
}
.a-icon__wrapper--100 > svg {
  width: 100px;
  height: 100px;
}
.a-icon__wrapper--101 > svg {
  width: 101px;
  height: 101px;
}
.a-icon__wrapper--102 > svg {
  width: 102px;
  height: 102px;
}
.a-icon__wrapper--103 > svg {
  width: 103px;
  height: 103px;
}
.a-icon__wrapper--104 > svg {
  width: 104px;
  height: 104px;
}
.a-icon__wrapper--105 > svg {
  width: 105px;
  height: 105px;
}
.a-icon__wrapper--106 > svg {
  width: 106px;
  height: 106px;
}
.a-icon__wrapper--107 > svg {
  width: 107px;
  height: 107px;
}
.a-icon__wrapper--108 > svg {
  width: 108px;
  height: 108px;
}
.a-icon__wrapper--109 > svg {
  width: 109px;
  height: 109px;
}
.a-icon__wrapper--110 > svg {
  width: 110px;
  height: 110px;
}
.a-icon__wrapper--111 > svg {
  width: 111px;
  height: 111px;
}
.a-icon__wrapper--112 > svg {
  width: 112px;
  height: 112px;
}
.a-icon__wrapper--113 > svg {
  width: 113px;
  height: 113px;
}
.a-icon__wrapper--114 > svg {
  width: 114px;
  height: 114px;
}
.a-icon__wrapper--115 > svg {
  width: 115px;
  height: 115px;
}
.a-icon__wrapper--116 > svg {
  width: 116px;
  height: 116px;
}
.a-icon__wrapper--117 > svg {
  width: 117px;
  height: 117px;
}
.a-icon__wrapper--118 > svg {
  width: 118px;
  height: 118px;
}
.a-icon__wrapper--119 > svg {
  width: 119px;
  height: 119px;
}
.a-icon__wrapper--120 > svg {
  width: 120px;
  height: 120px;
}
.a-icon__wrapper--121 > svg {
  width: 121px;
  height: 121px;
}
.a-icon__wrapper--122 > svg {
  width: 122px;
  height: 122px;
}
.a-icon__wrapper--123 > svg {
  width: 123px;
  height: 123px;
}
.a-icon__wrapper--124 > svg {
  width: 124px;
  height: 124px;
}
.a-icon__wrapper--125 > svg {
  width: 125px;
  height: 125px;
}
.a-icon__wrapper--126 > svg {
  width: 126px;
  height: 126px;
}
.a-icon__wrapper--127 > svg {
  width: 127px;
  height: 127px;
}

.a-button {
  text-decoration: none;
  transition: all 0.275s ease-in-out;
}
.a-button--primary {
  text-transform: uppercase;
  text-align: right;
  @apply pt-4 pr-4 pb-2 pl-24;
  border-bottom: 8px solid #5194A9;
  font-size: 0.75rem;
  letter-spacing: 5px;
}
.a-button--more {
  margin: 0;
}

@keyframes exitUp {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  70% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-200px);
    opacity: 0;
  }
}
.a-alert {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 200px;
  z-index: 99;
  font-size: 1.2em;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: exitUp 4.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  animation-fill-mode: both;
}
.a-alert--success {
  background: #048040;
  color: #fff;
}

form {
  max-width: 800px;
}
form label {
  display: inline-block;
  padding-bottom: 0.5rem;
}
form input, form textarea {
  display: block;
  width: 100%;
  border: 1px solid #4E4E4E;
  font-family: europa, sans-serif;
  color: #000;
  background: #fff;
  transition: 0.3s ease-in-out border-color;
  border-radius: 2px;
  font-size: 1.3rem;
  padding: 5px 20px;
  margin-bottom: 1rem;
}
form input:focus, form textarea:focus {
  outline: none;
  border-color: #5194A9;
}
form textarea {
  min-height: 150px;
}
@media (min-width: 48em) {
  form .input > div {
    display: flex;
  }
}
form select {
  margin-right: 20px;
  height: 64px;
  border: 1px solid #4E4E4E;
  margin-bottom: 1rem;
}
@media (max-width: 47.99em) {
  form select {
    width: 100%;
  }
}
@media (min-width: 62em) {
  form select {
    height: 67px;
  }
}
@media (min-width: 90em) {
  form select {
    height: 72px;
  }
}
form .submit {
  margin-left: -10px;
}
form button {
  transform: translateY(-3px);
}

.a-form__wrapper {
  @apply mx-auto;
}
.a-form__wrapper > form {
  @apply flex flex-wrap;
}
.a-form__group {
  @apply w-full;
}
@media (min-width: 48em) {
  .a-form__group.half-field {
    @apply w-1/2;
    padding: 0 2px;
  }
}
.a-form__recaptcha {
  @apply w-full;
}

.alert {
  @apply p-4 mb-8;
}

.alert--success {
  background-color: #88C695;
}

.a-logo__wrapper {
  @apply flex h-full content-center;
  padding: 8px 0;
  max-width: 280px;
}
@media (min-width: 62em) {
  .a-logo__wrapper {
    padding: 10px 0;
  }
}
.a-logo__link {
  @apply w-full;
}
.a-logo__image {
  object-fit: contain;
  height: 85px;
  transition: height 0.2s ease-in-out;
}
@media (min-width: 62em) and (max-width: 112.49em) {
  .a-logo__image {
    height: 90px;
  }
}
@media (min-width: 112.5em) {
  .a-logo__image {
    height: 130px;
  }
}

.m-articlesListing__text {
  @apply relative pt-2 pb-2;
  background-color: rgba(210, 210, 210, 0.95);
}
.m-articlesListing__text.utl-has-image {
  background-color: rgba(220, 220, 220, 0.8);
}
.m-articlesListing__heading {
  color: #696969;
  transition: all 0.275s ease-in-out;
}
.m-articlesListing__heading-link {
  @apply block p-4;
  text-decoration: none;
  min-height: 255px;
}
.m-articlesListing__details {
  color: #696969;
  transition: all 0.275s ease-in-out;
}
.m-articlesListing__description {
  font-size: 1.1em;
  font-style: italic;
}
.m-articlesListing__more {
  text-align: center;
  text-transform: uppercase;
  color: #4E4E4E;
  font-size: 0.8em;
  letter-spacing: 2px;
}
.m-articlesListing:hover .m-articlesListing__text, .m-articlesListing:active .m-articlesListing__text, .m-articlesListing:focus .m-articlesListing__text {
  @apply pt-2 pb-2;
  color: #fff;
  background-color: #5194A9;
}
.m-articlesListing:hover .m-articlesListing__heading, .m-articlesListing:active .m-articlesListing__heading, .m-articlesListing:focus .m-articlesListing__heading {
  color: #fff;
}
.m-articlesListing:hover .m-articlesListing__details, .m-articlesListing:active .m-articlesListing__details, .m-articlesListing:focus .m-articlesListing__details {
  color: #fff;
}

.m-articlesListing__text.utl-has-image {
  top: 0;
}

.m-locations {
  @apply my-12;
}
@media (min-width: 62em) {
  .m-locations {
    @apply flex;
  }
}
.m-locations__section {
  @apply pt-8 w-full;
}
@media (min-width: 48em) {
  .m-locations__section {
    @apply pt-24;
  }
}
.m-locations__tabs {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
@media (min-width: 62em) {
  .m-locations__tabs {
    display: block;
  }
}
.m-locations__tabs__button {
  display: flex;
  align-items: center;
  padding: 5px 20px;
  background: #fff;
  color: #000;
  border: 1px #d4d4d4 solid;
  width: 100%;
  margin-bottom: 5px;
  transition: all 0.275s ease-in-out;
}
@media (min-width: 48em) {
  .m-locations__tabs__button {
    width: 48%;
    margin-bottom: 20px;
  }
}
@media (min-width: 62em) {
  .m-locations__tabs__button {
    width: 300px;
    margin-right: 20px;
    padding: 20px 30px;
  }
}
.m-locations__tabs__button svg {
  color: #000;
  height: 25px;
  width: 25px;
  margin-right: 10px;
}
.m-locations__tabs__button__title {
  @apply mb-0;
  font-size: 1.2em;
  font-weight: 300;
  color: #4E4E4E;
  padding: 0.5em 0;
  transition: all 0.275s ease-in-out;
}
.m-locations__tabs__button.active {
  background: #efefef;
  border: 1px #efefef solid;
}
.m-locations__tabs__button:not(.active):hover, .m-locations__tabs__button:not(.active):active, .m-locations__tabs__button:not(.active):focus {
  background: #5194A9;
  border-color: #5194A9;
}
.m-locations__tabs__button:not(.active):hover .c-locations__tabs__button__title, .m-locations__tabs__button:not(.active):active .c-locations__tabs__button__title, .m-locations__tabs__button:not(.active):focus .c-locations__tabs__button__title {
  padding-left: 5px;
  color: #fff;
}
.m-locations .js-locations-animate {
  @apply flex flex-col w-full;
  opacity: 0;
  margin-top: 100px;
  animation: locationAnimate 0.8s cubic-bezier(0.25, 0.1, 0.25, 1);
  animation-fill-mode: both;
}
@keyframes locationAnimate {
  0% {
    opacity: 0;
    margin-top: 100px;
  }
  100% {
    opacity: 1;
    margin-top: 0;
  }
}
.m-locations__location {
  background: #efefef;
  width: 100%;
}
.m-locations__location__wrapper {
  @apply w-full;
}
.m-locations__location__info {
  @apply px-8 py-8;
}
@media (max-width: 47.99em) {
  .m-locations__location__info__address {
    margin-bottom: 10px;
  }
}
.m-locations__location__info__details {
  list-style-type: none !important;
}
.m-locations__location__map {
  min-height: 400px;
  width: 100%;
  background-color: #5194A9;
}

.link--blue {
  color: #5194A9;
}
.link--blue:hover, .link--blue:active, .link--blue:focus {
  color: #357184;
}

.js-animate--pending {
  opacity: 0;
}

.js-animate--start {
  opacity: 1;
}

@media (max-width: 61.99em) {
  .js-animate--delay {
    animation-delay: 0.45s;
  }

  .js-animate--delay:nth-child(-n+4) {
    animation-delay: 0.3s;
  }

  .js-animate--delay:nth-child(-n+2) {
    animation-delay: 0.15s;
  }
}
@media (min-width: 62em) {
  .js-animate--delay1 {
    animation-delay: 0.15s;
  }
}

@media (min-width: 62em) {
  .js-animate--delay2 {
    animation-delay: 0.3s;
  }
}

@media (min-width: 62em) {
  .js-animate--delay3 {
    animation-delay: 0.45s;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.js-animate--start > .js-animate--fade-in {
  animation-name: fadein;
  animation-duration: 0.95s;
  animation-fill-mode: both;
}

@keyframes scaleY {
  from {
    transform: scaleY(0.05);
  }
  to {
    transform: scaleY(1);
  }
}
.js-animate--start > .js-animate--scale-y {
  animation-name: scaleY;
  animation-duration: 0.95s;
  animation-fill-mode: both;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.js-animate--start > .b-buttons,
.js-animate--start > .js-animate--slide-down {
  animation-name: slideDown;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-timing-function: ease-out;
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.js-animate--start > .js-animate--slide-up {
  animation-name: slideUp;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
}

.swiper-button-next, .swiper-button-prev {
  color: #fff !important;
}

.swiper-pagination-bullet-active, .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background-color: #fff !important;
}

.swiper-lazy-preloader {
  border: 4px solid #fff !important;
}

.utl-transform-on-hover {
  transition: 0.2s transform ease-in-out;
}
.utl-transform-on-hover--scale {
  transform: scale(1);
}

.utl-icon-on-hover {
  opacity: 0;
  transition: 0.2s opacity ease-in-out;
  z-index: 10;
}

.utl-hover-effect:hover .utl-transform-on-hover--scale, .utl-hover-effect:active .utl-transform-on-hover--scale, .utl-hover-effect:focus .utl-transform-on-hover--scale {
  transform: scale(1.05);
}
.utl-hover-effect:hover .utl-icon-on-hover, .utl-hover-effect:active .utl-icon-on-hover, .utl-hover-effect:focus .utl-icon-on-hover {
  opacity: 1;
}

.circle {
  border-radius: 100%;
}

.b-entries__image-wrapper,
.box-shadow {
  box-shadow: 0 10px 90px rgba(0, 0, 0, 0.2);
}

.b-entries__image-wrapper:hover, .b-entries__image-wrapper:active, .b-entries__image-wrapper:focus,
.box-shadow--lg {
  box-shadow: 0 30px 120px rgba(0, 0, 0, 0.15);
}

.full-width {
  width: 100%;
}

.flex-grid {
  margin-left: -8px;
  margin-right: -8px;
}
.flex-grid > * {
  padding: 8px;
}

.centred {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@media (min-width: 75em) {
  .hidden--desktop {
    @apply hidden;
  }
}

@media (max-width: 74.99em) {
  .hidden--mobile {
    @apply hidden;
  }
}

@media (max-width: 35.99em) {
  .hidden--xs {
    @apply hidden;
  }
}

@media (min-width: 36em) and (max-width: 47.99em) {
  .hidden--sm {
    @apply hidden;
  }
}

@media (min-width: 48em) and (max-width: 61.99em) {
  .hidden--md {
    @apply hidden;
  }
}

@media (min-width: 62em) and (max-width: 89.99em) {
  .hidden--lg {
    @apply hidden;
  }
}

@media (min-width: 90em) and (max-width: 112.49em) {
  .hidden--xl {
    @apply hidden;
  }
}

@media (min-width: 112.5em) {
  .hidden--xxl {
    @apply hidden;
  }
}

.no-js .js-lazy {
  display: none;
}

.loading {
  opacity: 0;
}

.loaded {
  opacity: 1;
}

.ie-support::after {
  content: "You are using a browser that is not supported. For a more consistent experience please use a different browser.";
  position: absolute;
  top: 100%;
  left: 0;
  padding: 20px;
  text-align: center;
  width: 100vw;
  background: #4870B8;
  color: #fff;
  display: block;
}

figure {
  margin: 2rem 0;
  max-width: 100% !important;
}
figure > img {
  display: inline-block !important;
}

.alert {
  @apply p-4 mb-8;
  background-color: #f2f2f2;
}
.alert > p {
  @apply mb-0;
}

.alert-danger {
  background-color: #ffe3e3;
}

.alert--success {
  background-color: #88C695;
}