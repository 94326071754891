.relatedArticles {
    @apply my-24;

    &__wrapper {
        @apply flex flex-wrap justify-around my-12 mt-4;
    }

    &__article {
        position: relative;
        flex-basis: 80%;
        max-width: 80%;

        @include mq(md) {
            flex-basis: 31.33%;
            max-width: 31.33%;
        }

        &__link {}

        &__text {
            justify-content: space-between;

            svg {
                height: 20px;
                min-width: 20px;
                color: $color__primary;
                margin: 1.4em 1.5em 0;
                transition: transform 0.3s ease-in-out;
            }
        }
        @include attention() {
            svg {
                transform: translate(1.2em);
            }
        }
    }
}
