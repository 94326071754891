$color__primary: #5194A9; // primary buttons
$color__primary--dark: #357184; // primary hover effects
$color__primary--alt: $color__primary--dark; // active sidebar navs

$color__secondary: #68739a;
$color__secondary--2: #2F3444;
$color__secondary--3: #1E212D;
$color__secondary--4: #15171E;
$color__secondary--5: #0C0D11;

// Go make your own at https://meyerweb.com/eric/tools/color-blend/#EFEFEF:333333:6:hex
$color__grey--100: #efefef;
$color__grey--200: #d4d4d4;
$color__grey--300: #b9b9b9;
$color__grey--400: #9e9e9e;
$color__grey--500: #848484;
$color__grey--600: #696969;
$color__grey--700: #4E4E4E;
$color__grey--800: #333333;

$color__link: $color__primary;
$color__link--attention: $color__secondary;

$color__page-background: #3d4150;

$color__white: #fff;
$color__black: #000;
$color__red: #ce1f06;
$color__orange: #ee8600;
$color__yellow: #ffd404;
$color__blue: #4870B8;
$color__green: #048040;

$hamburger-color: $color__white;
$swiper__theme: $color__white;
