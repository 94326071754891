$card-tease-background-colour: $color__grey--100 !default;
$card-tease-heading-weight: $font-weight-bold !default;

@if index($tease-types, card) {
    .a-tease--list {
        @include mq("mobile-menu") {
          flex-basis: 100%;
        }

        > .a-tease__link {
            @include mq("mobile-menu") {
                display: flex;
            }
        }

        &__image-wrapper {
            @include mq("mobile-menu") {
                flex-basis: 100%;
            }
        }

        &__image {}

        &__heading {
            @apply mt-3 mb-0;
            font-weight: $card-tease-heading-weight;
        }

        &__text {
            @apply py-4 px-8 w-full;
            justify-content: space-between;
            background-color: $card-tease-background-colour;
        }
    }
}
