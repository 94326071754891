.js-animate--pending {
    opacity: 0;
}

.js-animate--start {
    opacity: 1;
}

@include mq($until: lg) {
    .js-animate--delay {
        animation-delay: 3 * 0.15s;
    }

    .js-animate--delay:nth-child(-n+4) {
        animation-delay: 2 * 0.15s;
    }

    .js-animate--delay:nth-child(-n+2) {
        animation-delay: 0.15s;
    }
}

@for $i from 1 to 4 {
    .js-animate--delay#{$i} {
        @include mq("lg") {
            animation-delay: $i * 0.15s;
        }
    }
}

@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

%js-animate--fade-in,
.js-animate--start > .js-animate--fade-in {
    animation-name: fadein;
    animation-duration: 0.95s;
    animation-fill-mode: both;
}

@keyframes scaleY {
    from {
        transform: scaleY(0.05);
    }

    to {
        transform: scaleY(1);
    }
}

%js-animate--scale-y,
.js-animate--start > .js-animate--scale-y {
    animation-name: scaleY;
    animation-duration: 0.95s;
    animation-fill-mode: both;
}

@keyframes slideDown {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

%js-animate--slide-down,
.js-animate--start > .js-animate--slide-down {
    animation-name: slideDown;
    animation-duration: 0.5s;
    animation-fill-mode: both;
    animation-timing-function: ease-out;
}

@keyframes slideUp {
    from {
        opacity: 0;
        transform: translateY(10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

%js-animate--slide-up,
.js-animate--start > .js-animate--slide-up {
    animation-name: slideUp;
    animation-duration: 0.5s;
    animation-fill-mode: both;
    animation-timing-function: ease-in-out;
}
