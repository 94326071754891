@if index($hamburger-animations, collapse) {
  .a-nav-toggle--collapse {
      span.bar {
          transition: transform .4s / $hamburger-animation-speed $hamburger-easing,
                      top .25s / $hamburger-animation-speed $hamburger-easing .25s / $hamburger-animation-speed,
                      opacity .25s / $hamburger-animation-speed $hamburger-easing .25s / $hamburger-animation-speed;
      }

      &.utl-is-active {
          span.bar {
              top: 0;
              transition: transform .4s / $hamburger-animation-speed $hamburger-easing .1s / $hamburger-animation-speed,
                          top .25s / $hamburger-animation-speed $hamburger-easing,
                          opacity .25s / $hamburger-animation-speed $hamburger-easing;

              &:nth-child(1) {
                  transform: rotate(135deg);
              }

              &:nth-child(2) {
                  opacity: 0;
              }

              &:nth-child(3) {
                  transform: rotate(45deg);
              }
          }
      }
  }
}
