$nav-secondary-link-color: $color__white !default;
$nav-secondary-link-color--hover: $color__grey--200 !default;

.c-nav--secondary {
    @apply hidden;

    @include mq("mobile-menu") {
        font-size: 0.9rem;
        @apply inline-block w-full items-center;
    }

    &__wrapper {
        @apply flex flex-grow flex-wrap;
    }

    &__list {
        @apply flex justify-end list-none;

        &-item {
            @apply relative inline-flex;

            padding: 0 ($nav-primary-link-spacing / 3);

            &-link {
                @apply relative self-center;

                color: $nav-secondary-link-color;
                padding: 3px ($nav-primary-link-spacing / 2);
                text-decoration: none;

                @include attention() {
                    color: $nav-secondary-link-color--hover;
                }

                > svg {
                    width: 14px;
                    height: 14px;
                    margin-right: 10px;
                    align-self: center;
                }
            }
        }
    }
}
