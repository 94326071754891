$footer-primary-background: $color__secondary !default;
$footer-primary-text-color: $color__grey--200 !default;
$footer-primary-text-link-color: $color__primary--dark;
$footer-primary-text-link-color--hover: rgba($footer-primary-text-link-color, 0.75) !default;
$footer-primary-text-link-decoration: underline;
$footer-primary-text-link-decoration--hover: none !default;

.c-footer--primary {

    @apply py-8;

    background: $color__secondary--5;
    color: $color__grey--100;

    @include mq("md") {
        @apply py-20;
    }

    &__link {
        @apply flex;
        flex-direction: column;
        margin-bottom: 1.5rem;
        color: $color__grey--100;


        @include attention() {
            color: $footer-primary-text-link-color--hover;
            text-decoration: $footer-primary-text-link-decoration--hover;

        }
        @media (min-width: "1024px"){
            align-items: flex-end;

        }
        a{
            color: $color__grey--100;
        }

        &__muted {
            color: $color__grey--100;
        }
        &--page{
            @apply block;
            margin-bottom: 1rem;
            color: $footer-primary-text-link-color;
            text-decoration: $footer-primary-text-link-decoration;

            @include attention() {
                color: $footer-primary-text-link-color--hover;
                text-decoration: $footer-primary-text-link-decoration--hover;

            }
        }
    }

    &__wrapper {
    }

    &__column {
        display: flex;
        flex-direction: column;
        margin-top: 2rem;

            &--first {
                @include mq($from: md, $until: lg) {
                    flex: 1;
                }
                flex-grow: 1;
            }
            @include mq("lg") {
                margin-left: 330px;
                display: flex;
                flex-direction: column;
                line-height: 2;
                align-items: flex-end;
            }
    }

    &__nav {
        @include mq("md") {
            column-count: 1;
        }
    }
    &__title{
        margin-bottom: 0 auto;

        @media (min-width: "1210px"){
            margin-top: 2rem;
        }
    }

    &__contact {
        margin-left: -10px;

        @include mq($until:md) {
          @apply pt-4;
        }

        &-link {
            @apply flex;
            color: $footer-primary-text-link-color;
            text-decoration: none;

            @include attention() {
                color: $footer-primary-text-link-color--hover;
            }

            > svg {
                width: 18px;
                height: 18px;
                margin-right: 10px;
                align-self: center;
            }
        }
    }
}
