$font-family-default: europa, sans-serif;;
$font__secondary: europa, sans-serif;;
$font__tertiary: europa, sans-serif;;

$text-color: $color__white;
$headings-color: $color__white;

$font-weight-ultralight: 300 !default;
$font-weight-light: 300 !default;
$font-weight-regular: 400 !default;
$font-weight-semibold: 700 !default;
$font-weight-bold: 700 !default;
$font-weight-black: 700 !default;

$line-height-small: 1.3;
$line-height-default: 1.5;
$line-height-large: 1.65;
$line-height-xlarge: 1.8;

$headings-font-family: $font__secondary;
$headings-font-weight: $font-weight-bold !default;
$headings-line-height: $line-height-default !default;

// Base font size
$font-size-default: 18px;

$font-size-xsmall: 0.6em;
$font-size-small: 0.8em;
$font-size-large: 1.2em;
$font-size-xlarge: 1.5em;

// Heading sizes - https://www.modularscale.com/?1&em&1.175
$font-size-h1: 2.632em;
$font-size-h2: 2.24em;
$font-size-h3: 1.906em;
$font-size-h4: 1.622em;
$font-size-h5: 1.381em;
$font-size-h6: 1.175em;

// Banner headings
$font-size-banner-heading-min: 20px;
$font-size-banner-heading-max: 35px;

// eg p.lead
$font-size-large-min: 14px;
$font-size-large-max: 18px;

$paragraph-spacing: 2rem;
