$footer-secondary-background: $color__secondary--4;
$footer-secondary-text-color: $color__grey--300;
$footer-secondary-text-link-color: $color__link !default;
$footer-secondary-text-link-color--hover: rgba($footer-secondary-text-link-color, 0.75) !default;
$footer-secondary-text-link-decoration: none !default;
$footer-secondary-text-link-decoration--hover: none !default;

.c-footer--secondary {
    @apply relative;

    background: $footer-secondary-background;
    color: $footer-secondary-text-color;

    @apply py-2;

    @include mq("md") {
        @apply py-4;
    }

    &__wrapper {
        @apply flex flex-wrap;
    }

    &__column {
        @apply w-full;

        &--1 {
            @include mq("md") {
                @apply w-3/4;
            }
        }

        &--2 {
            @apply mt-1 text-center;
            @include mq("md") {
                @apply w-1/4;
            }

            @include mq("xl") {
                @apply text-right;
            }
        }
    }

    &__list {
        @apply flex mx-2 list-none p-0 m-0;

        @include mq("md") {}

        li + li:before {
            content: "|";
            padding: 0 5px;
        }

        &-item {
            &__link {
                color: $footer-secondary-text-link-color;
                text-decoration: $footer-secondary-text-link-decoration;
                text-decoration: none;

                @include attention() {
                    color: $footer-secondary-text-link-color--hover;
                    text-decoration: $footer-secondary-text-link-decoration--hover;
                }
            }
        }
    }

    &__credit {

        @include mq("lg") {
            @apply text-right;
        }
    }
}
