$banner-background-colour: #050406 !default;
$banner-text-colour: $color__white !default;

// Banner style (for multiple, comma separated). Use this as a variable and remove the styles
// you don't need so they won't get compiled in your css.
$banner-style: (
    slider,
    video,
    image
)
!default;

.banner {
    @apply relative overflow-hidden bg-cover;
    background-color: $banner-background-colour;

    &.banner--small {
        height: 275px;

        @include mq($from: lg, $until: 1799px) {
            height: 0;
            padding-bottom: 22% !important;
        }

        @include mq($from: 1800px) {
            height: calc(50vh - 247px);
            min-height: 300px;
            max-height: 440px;
        }
    }

    &.banner--standard {
        height: 400px;

        @include mq($from: lg, $until: 1799px) {
            height: 0;
            padding-bottom: 33.3% !important;
        }

        @include mq($from: 1800px) {
            height: calc(70vh - 247px);
            min-height: 400px;
            max-height: 640px;
        }
    }

    &.banner--large {
        height: 500px;

        @include mq($from: lg, $until: 1799px) {
            height: 0;
        }

        @include mq($from: lg, $until: xl) {
            margin-bottom: 98px;
            padding-bottom: 43% !important;
        }

        @include mq($from: xl, $until: 1799px) {
            margin-bottom: 70px;
            padding-bottom: 45.5% !important;
        }

        @include mq($from: 1800px) {
            height: calc(100vh - 247px);
            min-height: 600px;
            max-height: 713px;
            margin-bottom: 190px;
        }
    }

    &__text {
        @apply absolute z-10 w-full;

        top: 50%;
        transform: translateY(-50%);
        color: $banner-text-colour;
        font-size: 1.2rem;

        &.banner__text--left {
            max-width: 750px;
        }

        &.banner__text--center {
            @apply text-center;
        }

        &.banner__text--right {
            @apply text-right;

            max-width: 750px;
            right: 0;
        }

        &__heading {
            @apply mb-2;

            color: $banner-text-colour;
            text-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
        }
        &__description {
            text-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
        }
    }

    &__image {
        top: 50%;
        transform: translateY(-50%);
        position: absolute;
        width: 100%;

        @include mq($until: lg) {
            height: 100%;

            > picture {
                width: 100%;
                height: 100%;
                display: flex;

                > img {
                    object-fit: cover;
                    height: auto;
                    width:100%;
                }
            }
        }
    }

    &__links {
        @apply mt-6;
        margin-left: -10px;
        margin-right: -10px;
    }
}

.no-js .banner__image > picture {
    display: none;
}

@import 'banner-options/banner--slider';
@import 'banner-options/banner--video';
@import 'banner-options/banner--image';
