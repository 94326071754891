form {
    max-width: 800px;

     label {
        display: inline-block;
        padding-bottom: 0.5rem;
    }

    input, textarea {
        display: block;
        width: 100%;
        border: 1px solid $color__grey--700;
        font-family: $font-family-default;
        color: $color__black;
        background: $color__white;
        transition: 0.3s ease-in-out border-color;
        border-radius: 2px;
        font-size: 1.3rem;
        padding: 5px 20px;
        margin-bottom: 1rem;

        &:focus {
            outline: none;
            border-color: $color__primary;
        }
    }

    textarea {
        min-height: 150px;
    }

    .input > div {
        @include mq("md") {
            display: flex;
        }
    }

    select {
        margin-right: 20px;
        height: 64px;
        border: 1px solid $color__grey--700;
        margin-bottom: 1rem;

        @include mq($until: md) {
          width: 100%;
        }

        @include mq("lg") {
            height: 67px;
        }

        @include mq("xl") {
            height: 72px;
        }
    }

    .submit {
        margin-left: -10px;
    }

    button {
        @extend %a-button;
        @extend %a-button--primary;

        transform: translateY(-3px);
    }
}

.a-form {
    &__wrapper {
        @apply mx-auto;

        > form {
            @apply flex flex-wrap;
        }
    }

    &__group {
        @apply w-full;

        &.half-field {
            @include mq("md") {
                @apply w-1/2;
                padding: 0 2px;
            }
        }
    }

    &__recaptcha {
        @apply w-full;
    }
}

.alert {
    @apply p-4 mb-8;
}

.alert--success {
    background-color: #88C695;
}
