.c-prevNext {
    @apply py-12;

    &__link {
        @apply flex;

        @include mq("md") {
          @apply w-1/2;
        }

        &--left {
            @include mq("md") {
              @apply float-left;
            }
        }
        &--right {
            @apply justify-end;

            @include mq("md") {
              @apply float-right;
            }
        }
    }
    &__icon {
        @apply my-2;

        &--left {
            @apply mr-4;
            transform: scaleX(-1);
            filter: FlipH;
        -ms-filter: "FlipH";
        }
        &--right {
            @apply ml-4;
        }
    }
}
