$shutter-title-background: $color__grey--100;
$shutter-title-hover-background: $color__primary;
$shutter-title-active-background: $color__secondary;
$shutter-title-active-hover-background: $shutter-title-hover-background;
$shutter-title-active-color: $color__white;
$shutter-title-active-hover-color: $color__white;
$shutter-title-color: $color__secondary;
$shutter-title-hover-color: $color__white;
$shutter-transition: all 0.25s ease-in-out;
$blocks: (
    buttons,
    call-to-action,
    entries,
    image-text,
    images,
    shutters,
    text
);
