/// Very simple, *very* pragmatic mixin for applying hover and active states in one go:
/// @link https://twitter.com/csswizardry/status/478938530342006784 Original tweet from Harry Roberts
/// When we give an element some ‘attention’
@mixin attention() {

  &:hover,
  &:active,
  &:focus {
      @content;
  }

}
