
.c-image-matrix {
    @apply pt-8 pb-8 px-24;
    position: absolute;

    &__container {
        @apply -mx-24;
    }
            &__heading {
                position: relative;
            }
            &__content {
                  position: relative;
                  font-size: 0.9em;
                  z-index: 1;
                  color: $text-color;
              }
            &__image {
                position: relative;
            }
            &__link{

            }
                    &.u-l-1 {
                      &.u-i-1 {
                          &.c-image-matrix {
                              @apply w-full relative;
                              &::before {
                                  @apply absolute -ml-8;
                                  content: '';
                                  margin: 2rem;
                                  background-color: $color__secondary--2;
                                  top:0;
                                  right:0;
                                  bottom:0;
                                  left:0;
                              }

                          &__image {
                          }
                          &__heading {
                          }
                          &__content {
                          }
                          &__link{

                          }

                      @include mq("lg") {
                         @apply w-3/5;
                      }
                    }
                }
            }
                    &.u-l-2 {
                      &.u-i-1 {
                        &.c-image-matrix {
                            @apply w-full relative;
                            &::before {
                                @apply absolute -ml-8;
                                content: '';
                                margin: 2rem;
                                background-color: $color__secondary--2;
                                top:0;
                                right:0;
                                bottom:0;
                                left:0;
                            }
                            &__image {
                            }
                            &__heading {
                            }
                            &__content {
                            }
                            &__link{

                              color: $color__primary!important;
                            }
                            @include mq("lg") {
                               @apply w-3/5;
                            }
                        }
                      }
                      &.u-i-2 {
                        &.c-image-matrix {
                            @apply w-full relative;

                            &::before {
                                @apply absolute -mr-8;
                                content: '';
                                margin: 2rem;
                                background-color: $color__primary;
                                top:0;
                                right:0;
                                bottom:0;
                                left:0;
                            }
                            &__image {
                            }
                            &__heading {
                            }
                            &__content {

                            }
                            &__link{

                            }
                            @include mq("lg") {
                               @apply w-2/5;
                               margin-top: -12rem;
                            }
                        }
                      }
                    }
                    &.u-l-3 {
                      &.u-i-1 {
                        &.c-image-matrix {
                            @apply w-full relative;
                            &::before {
                                @apply absolute -ml-8;
                                content: '';
                                margin: 2rem;
                                background-color: $color__secondary--2;
                                top:0;
                                right:0;
                                bottom:0;
                                left:0;
                            }
                            &__image {
                            }
                            &__heading {
                            }
                            &__content {
                            }
                            &__link{

                              color: $color__primary!important;
                            }
                            @include mq("lg") {
                               @apply w-3/5;
                            }
                        }
                      }
                      &.u-i-2 {
                        &.c-image-matrix {
                            @apply w-full relative;

                            &::before {
                                @apply absolute -mr-8;
                                content: '';
                                margin: 2rem;
                                background-color: $color__primary;
                                top:0;
                                right:0;
                                bottom:0;
                                left:0;
                            }
                            &__image {
                            }
                            &__heading {
                            }
                            &__content {

                            }
                            &__link{

                            }
                            @include mq("lg") {
                               @apply w-2/5;
                               margin-top: -12rem;
                            }
                        }
                      }
                      &.u-i-3 {
                        &.c-image-matrix {
                            @apply w-full relative;
                            &::before {
                                @apply absolute -ml-8;
                                content: '';
                                margin: 2rem;
                                background-color: $color__primary;
                                top:0;
                                right:0;
                                bottom:0;
                                left:0;
                            }
                            &__image {
                            }
                            &__heading {
                            }
                            &__content {

                            }
                            &__link{

                            }
                            @include mq("lg") {
                               @apply w-2/5;
                            }
                        }
                      }
                  }
                  &.u-l-4 {
                    &.u-i-1 {
                            &.c-image-matrix {
                                @apply w-full relative;
                                &::before {
                                    @apply absolute -ml-8;
                                    content: '';
                                    margin: 2rem;
                                    background-color: $color__secondary--2;
                                    top:0;
                                    right:0;
                                    bottom:0;
                                    left:0;
                                }
                                &__image {
                                }
                                &__heading {
                                }
                                &__content {
                                }
                                &__link{
                                  color: $color__primary;
                                }
                                @include mq("lg") {
                                   @apply w-3/5;
                                }
                            }
                          }

                    &.u-i-2 {
                      &.c-image-matrix {
                           @apply w-full relative;

                           &::before {
                               @apply absolute -mr-8;
                               content: '';
                               margin: 2rem;
                               background-color: $color__primary;
                               top:0;
                               right:0;
                               bottom:0;
                               left:0;
                           }
                           &__image {
                           }
                           &__heading {
                           }
                           &__content {

                           }
                           &__link{

                           }
                           a{
                           color:$color__secondary--2!important;
                         }
                         a:hover{
                           color:$color__secondary;
                         }
                           @include mq("lg") {
                              @apply w-2/5;
                              margin-top: -12rem;
                           }
                      }
                    }
                    &.u-i-3 {
                      &.c-image-matrix {
                          @apply w-full relative;
                          &::before {
                              @apply absolute -ml-8;
                              content: '';
                              margin: 2rem;
                              background-color: $color__secondary--2;
                              top:0;
                              right:0;
                              bottom:0;
                              left:0;
                              @include mq("lg") {
                                  background-color: $color__primary;
                              }
                          }
                          &__image {
                          }
                          &__heading {
                          }
                          &__content {

                          }
                          &__link{

                          }
                          a{
                          color:$color__secondary--2!important;
                          a:hover{
                            color:$color__secondary;
                          }


                        }
                        a:hover{
                          color:$color__secondary;
                        }

                          @include mq("lg") {
                             @apply w-2/5;
                          }
                      }

                  }
                    &.u-i-4 {
                        &.c-image-matrix {
                            @apply w-full relative;

                            &::before {
                                @apply absolute -mr-8;
                                content: '';
                                margin: 2rem;
                                background-color: $color__primary;
                                top:0;
                                right:0;
                                bottom:0;
                                left:0;
                                @include mq(lg) {
                                    background-color: $color__secondary--2;
                                }
                            }
                            &__image {
                            }
                            &__heading {
                            }
                            &__content {
                            }
                            &__link{

                              color: $color__secondary--2;

                                }

                            @include mq("lg") {
                               @apply w-3/5;
                               margin-bottom: 12rem;
                            }
                        }
                      }
                  }
                }

@keyframes in1 {
    0% {
        opacity: 0;
        transform: translateX(-20px);
    }

    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}

@keyframes in2 {
    0% {
        opacity: 0;

        transform: translateY(-20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes in3 {
    0% {
        opacity: 0;
        transform: translateX(20px);
    }

    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}

@keyframes in4 {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

.js-animate--start {
    > .c-image-matrix {
        &.u-i-1 {
            animation: in1 1s ease;
            animation-fill-mode: both;
            animation-delay: 0.5s;
        }

        &.u-i-2 {
            animation: in2 1s ease;
            animation-fill-mode: both;
            animation-delay: 0.25s;
        }

        &.u-i-3 {
            animation: in3 1s ease;
            animation-fill-mode: both;
            animation-delay: 0.75s;
        }

        &.u-i-4 {
            animation: in4 1s ease;
            animation-fill-mode: both;
            animation-delay: 1s;
        }
    }
}
