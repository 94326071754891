.relatedArticle {
    &__wrapper {
        @apply w-full flex flex-col;
        padding: 1.5rem;
        postion: relative;
        max-width: 700px;
        @include mq(lg) {
            @apply w-1/2;
        }
    }
    &__text{
    }
    &__button {
        @apply w-full;
        padding: 1.5rem;
        text-align: right;
        border-bottom: 5px solid $color__primary;
        width: 50%;
        @include mq(md){
            width: 50%;
        }
    }
}
