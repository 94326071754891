.b-imageText {
    @apply mt-48 mb-20;

    &--standard {
        > .b-imageText__image {
            @apply mt-12 px-12;
            max-height: 300px;
            overflow: hidden;
            background-color: $color__primary;

            @include mq("md") {
                @apply mt-80 px-12;
            }

            @include mq("lg") {
                @apply mt-96 px-12;
            }

            img {
                object-fit: cover;
            }

            @include mq("lg") {
                @apply mt-32 ;
              max-height: 600px;
              overflow: visible;
              img {
                  // object-fit: contain;
                  margin-top: -70px;
                  overflow: visible;
              }
            }
        }
    }

    &--large {
        > .b-imageText__image {
            img {

            }
        }
    }

    &__image {
        @include mq($from:xl, $until:xxl) {
            @apply flex justify-center;
        }

        > picture {
            @include mq($from:xl, $until:xxl) {
                @apply flex justify-center;
            }

            > img {
                @apply w-full;

                @include mq($from:xl, $until:xxl) {
                    max-width: initial;
                }
            }
        }
    }

    &__text {
        @apply py-8 pl-0;

        > *:first-child {
            margin-top: 0;
        }

        > *:last-child {
            margin-bottom: 0;
        }

        &.utl-has-background-color {
            @apply pl-8;

            @include mq("xl") {
                @apply pl-12;
            }
        }
    }

    &.utl-position--left {
        > .b-imageText__text {
            @apply pl-8;

            @include mq("xl") {
                @apply pl-12;
            }
        }
    }

    &.utl-position--right {
        @include mq("lg") {
            @apply flex-row-reverse;
        }

        > .b-imageText__text {
            @apply pr-8;

            @include mq("xl") {
                @apply pr-12;
            }
        }
    }
}



@keyframes in5 {
    0% {
        transform: translateY(20px);
    }

    100% {
        transform: translateY(0px);
    }
}

@keyframes in6 {
    0% {
        transform: translateY(20px);
    }

    100% {
        transform: translateY(0px);
    }
}

.js-animate--start {
    > .b-imageText  {
        &__image {
            animation: in5 1s ease;
            animation-fill-mode: both;

            > picture > img {
                animation: in6 1s ease;
                animation-fill-mode: both;
            }
        }
    }
}
