@if index($blocks, images) {
    .b-images {
        &--default {
            display: flex;
            flex-wrap: wrap;
        }

      &__image {
          &--default {
              flex: 1 1 25%;
          }
      }
    }
}
