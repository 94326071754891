$button-font-weight: $font-weight-black !default;
$button-font-weight: $font-weight-black !default;
$button-primary-color: $color__white !default;
$button-primary-color--hover: $color__white !default;
$button-primary-gradient-bg-color-1: $color__primary--alt !default;
$button-primary-gradient-bg-color-2: $color__primary !default;
$button-primary-flat-bg-color: $color__primary !default;
$button-primary-flat-bg-color--hover: $color__primary--alt !default;
$button-secondary-color: $color__white !default;
$button-secondary-color--hover: $color__white !default;
$button-secondary-bg-color: $color__secondary !default;
$button-secondary-bg-color--hover: $color__primary !default;
$button-default-color: $color__black !default;
$button-default-color--hover: $color__black !default;
$button-default-bg-color: $color__grey--light !default;
$button-default-bg-color--hover: $color__grey--cool !default;
$button-border-color: $color__primary !default;
$button-border-color--hover: $color__white !default;
$button-border-bg-color: $color__primary !default;
$button-border-bg-color--hover: $color__primary !default; // Choose gradient or flat
$button-primary-style: (flat ) !default;
$button-margin-y: 5px !default;
$button-margin-x: 10px !default;
$button-padding-y: 20px !default;
$button-padding-x: 32px !default;

%a-button,
.a-button {
    display: inline-flex;
    align-items: center;
    font-weight: $button-font-weight;
    margin: $button-margin-y $button-margin-x;
    padding: $button-padding-y $button-padding-x;
    letter-spacing: 0.25px;

    &__arrow {
        @apply inline;
        @apply ml-2 mr-12;
        transition: margin $timing--default $ease-std;
    }

    &--primary {
        color: $button-primary-color;
        @if index($button-primary-style, gradient) {
            background: linear-gradient(to right, $button-primary-gradient-bg-color-1 30%, $button-primary-gradient-bg-color-2 80%);
            background-size: 300% 100%;
            background-position: 30% 50%;
        }
        @if index($button-primary-style, flat) {
            background: $button-primary-flat-bg-color;
        }
        transition: all $timing--default $ease-std;
        @include attention() {
            color: $button-primary-color--hover;
            text-shadow: 0 2px 20px rgba(230, 85, 13, 0.2);
            @if index($button-primary-style, gradient) {
                background-position: 85% 0;
            }
            @if index($button-primary-style, flat) {
                background: $button-primary-flat-bg-color--hover;
            }
            box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
            cursor: pointer;
        }
    }

    &--secondary {
        color: $button-secondary-color;
        background: $button-secondary-bg-color;
        @include attention() {
            color: $button-secondary-color--hover;
            background: $button-secondary-bg-color--hover;
        }
    }

    &--default {
        color: $button-default-color;
        background: $button-default-bg-color;
        @include attention() {
            color: $button-default-color--hover;
            background: $button-default-bg-color--hover;
        }
    }

    &--outline {
        color: $button-border-color;
        border: 1px solid $button-border-bg-color;
        @include attention() {
            color: $button-border-color--hover;
            background: $button-border-bg-color--hover;
        }
    }

    &--arrow {
        @apply relative overflow-hidden pl-0;
        text-indent: -999px;
        @include attention() {
            @apply pl-2;
        }

        &::after {
            @apply absolute z-10;
            content: "";
            background-image: url("/assets/images/icon--arrow--right.svg");
            color: $button-primary-flat-bg-color;
            width: 23px;
            height: 10px;
            right: 56px;
            top: 18px;
            background-size: contain;
        }
    }

    &--more {
        margin: 0;
    }

    &--search {
        margin: 0 0 0 -1px;
        transform: translate(0);
        border-radius: 0 5px 5px 0;
        @apply flex;
        align-items: center;

        svg {
            height: 20px;
            width: 20px;
            margin-right: 0.5em;
        }
    }

    &:first-of-type {
        margin-left: 0;
    }

    &:last-of-type {
        margin-right: 0;
    }
}

.utl-button-arrows {
    .a-button {
        @apply relative pr-16;
        @include attention() {
            @apply pr-20;
        }

        &::after {
            @apply absolute z-10;
            content: "";
            background-image: url("/assets/images/icon--arrow--right.svg");
            color: $button-primary-color;
            width: 23px;
            height: 10px;
            right: 30px;
            top: 18px;
            background-size: contain;
        }
    }
}
