.swiper-button-next, .swiper-button-prev {
    color: $swiper__theme !important;
}

.swiper-pagination-bullet-active, .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
    background-color: $swiper__theme !important;
}

.swiper-lazy-preloader {
    border: 4px solid $swiper__theme !important;
}
