.utl-transform-on-hover {
    transition: 0.2s transform ease-in-out;

    &--scale {
        transform: scale(1);
    }
}

.utl-icon-on-hover {
    opacity: 0;
    transition: 0.2s opacity ease-in-out;
    z-index: 10;
}

.utl-hover-effect {
    @include attention() {
        .utl-transform-on-hover {
            &--scale {
                transform: scale(1.05);
            }
        }

        .utl-icon-on-hover {
            opacity: 1;
        }
    }
}
