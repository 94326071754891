$tease-listing-m: 0 !default;
$tease-py: 30px !default;
$tease-mt: $tease-py !default;
$tease-types: (
    card,
    image-block,
    default
)
!default;

.c-tease-listing {
    margin-top: $tease-listing-m;

    @include mq("sm") {
        margin-left: -$tease-py / 2;
        margin-right: -$tease-py / 2;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
    }
}

.a-tease {
    position: relative;
    margin-top: $tease-mt;
    min-height: 90px;

    @include mq("sm") {
        padding-left: $tease-py / 2;
        padding-right: $tease-py / 2;
        flex-basis: 50%;
    }

    @include mq("lg") {
        flex-basis: 33.33%;
    }
    &__link {
        text-decoration: none;
    }

    &__image {}

    &__heading {}

    &__details {
        @apply mt-2 mb-3;

        font-size: 0.9em;

        &__date {
            @apply block;
        }
    }

    &__description {
        @apply block my-4;
    }

    &__author {
        font-weight: $font-weight-bold;
    }

    &__categories {
        &__category {
            font-weight: $font-weight-bold;
        }
    }

    &__cta {
        font-weight: $font-weight-bold;

        &--chevron {
            &__icon {
                @apply ml-2;

                transition: margin-left 0.225s ease-in-out;

                > svg {
                    height: 20px;
                    width: 20px;
                    margin-top: -2px;
                }
            }
        }

        &--button {
            margin-left: 0 !important;
            background-color: $color__grey--500;

            &__text {
                color: $color__white;
            }
        }
    }

    @include attention() {
      .a-tease__cta--button {
          background-color: $color__grey--600;
      }

      .a-tease__cta--chevron__icon {
          margin-left: 0.65rem;
      }
    }
}

.c-tease-listing--1 {
    > .a-tease {
        @include mq("lg") {
            flex-basis: 100%;
        }
    }
}

.c-tease-listing--2 {
    > .a-tease {
        @include mq("lg") {
            flex-basis: 50%;
        }
    }
}

.c-tease-listing--4 {
    > .a-tease {
        @include mq("lg") {
            flex-basis: 25%;
        }
    }
}

@import 'tease-options/tease-default';
@import 'tease-options/tease-card';
@import 'tease-options/tease-image-block';
@import 'tease-options/tease-list';
